import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import "./FrameMobileView.css";

function FrameMobileView({ show, setShow }) {
    const navigate = useNavigate();
    const handleClose = () => {
        setShow(false);
        navigate('/dashboard');
    };
    return (
        <Modal show={show} onHide={handleClose} size="lg" centered className="warning-msg-modal">
            <Modal.Header closeButton className="warning-msg-modal-header" closeVariant="white">
            </Modal.Header>
            <Modal.Body className="warning-msg-modal-body">
                <h4 className="warning-msg-modal-title">
                    Looks like you are accessing this page on a mobile device 📱
                    where the page may not render properly 📉. Please consider switching to desktop 🖥️.
                </h4>
            </Modal.Body>

            <Modal.Footer className="warning-msg-modal-footer">
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default FrameMobileView;
