import styles from './DialogModal.module.css';

const DialogModal = (props) => {

  const { position, options, dialogClickHandler, setIsOpen, setDialogModalData} = props;
  const style = {
    top: position.y + "px",
    left: position.x + "px",
  };

  const handleUpdate = (option, index) => {
    if (index === 0) {
      dialogClickHandler(option.action)
    } else {
      setDialogModalData((props) => {
        return { ...props, show: false };
      });
      setIsOpen(true)
    }
  }

  return (
    <div className={styles['dialogWrapper']} style={style}>
      {options.map((option, index) => (
        <div
          key={index}
          className={`${styles['dialogWrapper_options']} ${option.action}`}
          onClick={() => handleUpdate(option, index)}
        >
          {option.text}
        </div>
      ))}
    </div>
  );
};

export default DialogModal;
