/**
 * QuestionsModal component for displaying questions that the user should be asking. Ideally, the user should be able
 * to click on the question and the AI should be able to answer it.
 */
import React, { useState, useEffect, useRef } from "react";
import styles from "../view-edit-architecture.module.css";
import { CrossSvg, StartSvg } from "../../../../images/svg/icons";
import { useContext } from 'react';
import context from "../../../../store/create-context";

const QuestionsModal = ({
  items, // The list of questions to display.
  setItems,
  searchTextRef, // This is the reference to the main Ask AI search text input field. Will be passing control to it
                 // after clicking on a question.
}) => {
  const [hoveredIndex, setHoveredIndex] = useState(0);
  const [initialHeight, setInitialHeight] = useState(0);
  const [startY, setStartY] = useState(0);

  // Flag to indicate that the button for getting questions was clicked.
  const { queryStr, setQueryStr, buttonClicked, setButtonClicked } = useContext(context);
  
  const askAiResponseRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowUp") {
        setHoveredIndex((prevState) => (prevState > 0 ? prevState - 1 : 0));
      } else if (event.key === "ArrowDown") {
        setHoveredIndex((prevState) =>
          prevState < items.length - 1 ? prevState + 1 : items.length - 1
        );
      }};

    // Add the event listener to the window
    window.addEventListener("keydown", handleKeyDown);

    // Cleanup: remove the event listener
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleMouseDown = (e) => {
    e.preventDefault();
    if (askAiResponseRef.current) {
      setInitialHeight(askAiResponseRef.current.getBoundingClientRect().height);
      setStartY(e.clientY);
   }
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e) => {
    if (askAiResponseRef.current) {
      const deltaY = startY - e.clientY;
      const newHeight = initialHeight + deltaY;
      askAiResponseRef.current.style.height = `${newHeight}px`;
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  useEffect(() => {
    if (searchTextRef.current && buttonClicked) {
      searchTextRef.current.focus();
      const length = searchTextRef.current.value.length;
      searchTextRef.current.setSelectionRange(length, length);
      setItems([]); // Clear the items
      setButtonClicked(false); // Reset the flag
    }
  }, [queryStr]);

  const handleClick = (text) => {
    setButtonClicked(true)
    try {
      setQueryStr(text);
      if (searchTextRef.current) {
        searchTextRef.current.value = text;
      }
      setItems([])
    } catch (err) {
      console.error("Failed to handle click", err);
    }
  };

  return (
    <>
    <div className={styles['resize-handle']} onMouseDown={handleMouseDown}>
      <div
        className={styles['close-button']}
      >
        <span onClick={() => setItems([])} style={{position:'absolute'}}><CrossSvg/></span>
      </div>
    </div>
    <div className={styles["search-ask-ai-res-modal"]} style={{maxWidth:'450px'}}>
      {items.length > 0 && (
        <div ref={askAiResponseRef} className={styles["resize-ask-ai-res-modal"]}>
          <div
            className={styles['search-modal-class-content']}>
            {items?.map((item, index) => (
              <div
                className="d-flex flex-row"
                style={{
                  marginBottom: "1.0rem",
                }}
                key={index}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <div className={styles['search-modal-item']} onClick={() => handleClick(item)}
                  style={{opacity: index === hoveredIndex ? 1 : 0.5,  borderBottom: "1px solid #3f3f3f", width:'280px'}}
                >
                   <span>{item}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
    </>
  );
};


export default QuestionsModal;
