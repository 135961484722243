import React, { useState, useEffect, useMemo } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Spinner } from 'react-bootstrap';
import "./ManageNote.css";

export const ManageNote = ({showNote, setShowNote,fetchNote, saveNote, selectNodeForLinking}) => {
  const [noteContent, setNoteContent] = useState("");
  const [initialNoteContent, setInitialNoteContent] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const isSaveDisabled = useMemo(() => noteContent === initialNoteContent, [noteContent, initialNoteContent]);

  useEffect(() => {
    setLoading(true);
    fetchNote()
      .then((note) => {
        setNoteContent(note);
        setInitialNoteContent(note);
        setIsEditing(note === "");
        setLoading(false);
      })
      .catch(() => {
        setNoteContent("");
        setInitialNoteContent("");
        setIsEditing(true);
        setLoading(false);
      });
  }, []);


  const handleSave = () => {
    saveNote(noteContent)
      .then(() => {
        setIsEditing(false);
        alert("Note saved successfully.");
      })
      .catch(() => alert("Failed to save note."));
    setInitialNoteContent(noteContent)
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleDiscard = () => {
    setNoteContent("");
    setNoteContent(initialNoteContent);
    setShowNote(false);
  };

  return (
    <>
      <Modal
        show={showNote}
        onHide={() => setShowNote(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="res-header">
          Edit or Add Note for {`${selectNodeForLinking.type}:${selectNodeForLinking.id}`}
        </Modal.Header>
        <Modal.Body className="res-body">
          {loading ? (
            <Spinner animation="border" />
          ) : (
            <textarea
              className="note-textarea"
              maxLength="256"
              value={noteContent}
              onChange={(e) => setNoteContent(e.target.value)}
              readOnly={!isEditing}
              placeholder="Write a note for this node. Remember to stay within 256 characters."
            />
          )}
        </Modal.Body>
        <Modal.Footer className="res-footer">
          {isEditing ? (
            <>
              <Button onClick={handleSave} className="footer-button" disabled={isSaveDisabled || noteContent === ""}>
                Save
              </Button>
              <Button onClick={handleDiscard} className="footer-button">
                Discard
              </Button>
            </>
          ) : (
            <>
              <Button onClick={handleEdit} className="footer-button">
                Edit
              </Button>
              <Button onClick={() => setShowNote(false)} className="footer-button">
                Close
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};
