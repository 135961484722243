import React, { useState } from 'react';
import "./CloudScannerUserGuide.css";
import Footer from "../../../../NewLanding2/Footer";
import Navbar from "../../../../NewLanding2/Navbar";
import vector from "../../../../../images/Vector .svg";
import copy from "../../../../../images/copy.svg";
import correct from "../../../../../images/correct.svg";
import cross from "../../../../../images/cross.svg";

const CloudScannerUserGuide = () => {
    const [icon, setIcon] = useState(copy);
    const [isInverted, setIsInverted] = useState(false);

    const jsonExample = {
        "Version": "2012-10-17",
        "Statement": [
            {
                "Effect": "Allow",
                "Principal": {
                    "AWS": "arn:aws:iam::301001779573:root"
                },
                "Action": "sts:AssumeRole",
                "Condition": {
                    "StringEquals": {
                        "sts:ExternalId": "<Only_The_External_ID_Part_Created_In_Step_2>"
                    }
                }
            }
        ]
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(JSON.stringify(jsonExample, null, 2))
            .then(() => {
                setIcon(correct);
                setIsInverted(true); // Apply invert for correct icon
                setTimeout(() => {
                    setIcon(copy);
                    setIsInverted(false); // Reset invert
                }, 2000);
            })
            .catch(() => {
                setIcon(cross);
                setIsInverted(true); // Apply invert for cross icon
                setTimeout(() => {
                    setIcon(copy);
                    setIsInverted(false); // Reset invert
                }, 2000);
            });
    };

    return (
        <div>
            <div style={{ backgroundColor: '#e9edf5' }}>
                <Navbar/>
            </div>
            <div className="container">
                <div className="container-title">
                    AWS Cloud Scanner User Guide
                </div>
                <div className="container-image">
                    <img className="image" src={vector} alt="Vector" />
                </div>
                <div className="container-description">
                    <br/>
                    <br/>
                    <br/>
                    <h2>Step 1: Initial Navigation</h2>
                    <p>We believe you are already on the JigsawML's ephemeral project. Please be aware that if you close the project's browser tab/window then all the data associated with it will be lost forever and cannot be recovered. It is recommended to take a note of the project id and project name for further reference if needed.</p>
                    <ol>
                        <li>Click on the <strong>Demo</strong> button on the top navigation bar</li>
                        <li>Click on <strong>Connect Cloud Account</strong></li>
                        <li> (Optional) Click on <strong> Try a Demo Account</strong> and select one of the five demo accounts that we've set up for you to experience the platform. Upon selecting a demo account, details should auto-fill and you should now click on <strong>Give Access</strong>. Please follow the next steps if you wish to connect your own aws account.</li>
                        
                    </ol>
                    <h2>Step 2: Generate External ID</h2>
                    <p>If you are going to connect your aws account id for the first time in this project then you have to generate an external id which will be used to give JigsawML Read-Only access permissions to scan the resources.</p>
                    <ol>
                        <li>Select <strong>AWS</strong> as the Cloud Platform (currently AWS is only supported)</li>
                        <li>Provide your 12-digit aws account id in the field marked <strong>Enter Account ID</strong></li>
                        <li>Click on <strong>Get External ID</strong> button</li>
                        <li>In the row below, a text will appear that should be something like the following:
                            <ul>
                                <li><code>&lt;AWS Account ID you just provided&gt;: &lt;36 character UUID representing External ID&gt;</code></li>
                                <li>For example - if it showed <code>012345678901: 01234567-89ab-cdef-0123-456789abcdef</code> then <code>01234567-89ab-cdef-0123-456789abcdef</code> is the External ID for AWS Account ID <code>012345678901</code></li>
                            </ul>
                        </li>
                    </ol>
                    <h2>Step 3: Create IAM Role</h2>
                    <p>We will now create an IAM role in your aws account which uses the above generated External ID to give permission to JigsawML to be able to scan your AWS Account.</p>
                    <ol>
                        <li>Login to your AWS Console and go to IAM</li>
                        <li>Go to the <strong>Roles</strong> on the left panel</li>
                        <li><strong>Create role</strong> in the right section
                            <ul>
                                <li><strong>Trusted Entity Type:</strong> AWS account</li>
                                <li><strong>An AWS Account</strong> &gt; <strong>Another AWS Account</strong> &gt; <strong>301001779573</strong>
                                    <ul><li>this is JigsawML's AWS account ID, which will be used to scan the resources, so use this number as it is</li></ul>
                                </li>
                                <li>Select the checkbox <strong>Require external ID</strong></li>
                                <li>Specify the external id provided to you in Step 2</li>
                                <li>Click <strong>Next</strong></li>
                                <li>Permissions Policies
                                    <ul>
                                        <li>In the search box enter <strong>ReadOnlyAccess</strong></li>
                                        <li>Filter by Type: <strong>AWS managed - job function</strong></li>
                                    </ul>
                                </li>
                                <li>Select the checkbox <strong>ReadOnlyAccess</strong></li>
                                <li>Click <strong>Next</strong></li>
                                <li>Provide a name for the role and a description for it if you want to</li>
                                <li>Click <strong>Create role</strong></li>
                            </ul>
                        </li>
                        <li>Go to the <strong>Roles</strong> on the left panel
                            <ul>
                                <li>Select the role-name you provided in the above step</li>
                                <li>Copy the ARN as mentioned in the "Summary". It should look something like the following:
                                    <ul>
                                        <li><code>arn:aws:iam::&lt;your-aws-account-id&gt;:role/&lt;your-role-name&gt;</code></li>
                                        <li>For example - <code>arn:aws:iam::012345678901:role/userrolename</code></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>(Optional but recommended) Check if the <strong>Trust relationships</strong> of the role are correct and it looks something like the following:</li>
                        <div className="example-json">
                            <pre>
                                {JSON.stringify(jsonExample, null, 2)}
                            </pre>
                            <img
                                src={icon}
                                alt="Copy Status"
                                className="copy-icon"
                                style={isInverted ? { filter: "invert(1)" } : {}}
                                onClick={handleCopy}
                            />
                            {/* {copySuccess && <span className="copy-success">{copySuccess}</span>} */}
                        </div>
                    </ol>
                    <h2>Step 4: Initiate Scanning</h2>
                    <p>In the final step, use the IAM Role ARN created in Step 3 to scan resources</p>
                    <ol>
                        <li>Return to the JigsawML's same ephemeral project, verify the project id and project name is same as it was in Step 1.</li>
                        <li>Click <strong>Show List of Account IDs and corresponding External IDs</strong> and you should see your AWS account ID along with the external id that was generated above.</li>
                        <li>In the field <strong>Enter the IAM role ARN you created</strong> enter the ARN created in Step 3 and click on <strong>Give Access</strong></li>
                        <li>Wait for a few minutes for the cloud resources to appear. Note that it might take few minutes to show the resources, depending on the number of resources in the account. Feel free to explore and ask questions about your resources to the AI!</li>
                    </ol>
                    <br/>
                    <br/>
                    <p style={{ fontSize: 'larger', fontStyle: 'italic' }}>
                        <strong>Note:</strong> We adhere to the AWS-defined process to securely access resources in any account. Learn more about it{' '}
                        <a href="https://docs.aws.amazon.com/IAM/latest/UserGuide/id_roles_common-scenarios_third-party.html" target="_blank" rel="noopener noreferrer">here</a>.
                    </p>
                </div>
            </div>
            <Footer />
        </div>
    )
};

export default CloudScannerUserGuide;
