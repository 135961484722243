import React, { useContext, useState } from "react";
import ModalUI from "../../components/UI/ModalUI/ModalUI";
import { Row, Col, Form } from "react-bootstrap";
import context from "../../../store/create-context";
import styles from "./Projects.module.css";
import { TickSvg } from "../../../images/svg/icons";
import { useNavigate } from "react-router-dom";

const AddProjectModal = (props) => {
  const [projectName, setProjectName] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { show, handleClose, modalTitle, sandboxMode } = props;
  const isDevEnvironment = process.env.REACT_APP_ENVIRONMENT === "dev";
  //context
  const { projects, setProjects, setShowToastr } = useContext(context);

  const navigate = useNavigate();

  //set projects
  const handleGetProjects = (data) => {
    if (data?.success === false) {
      setShowToastr({
        show: true,
        type: "error",
        text: data?.error
      });
    }
   else if (data && data.projects) setProjects(data.projects);
  };

  const handleCreateProject = () => {
    if (props.socket) {
      const socket = props.socket;

      // Check id the project name already exists
      const isDuplicate = projects.some(
        (project) => project.name.toLowerCase() === projectName.toLowerCase()
      );

      if (isDuplicate) {
        setErrorMessage("Project with the same name already exists");
        console.error("Project with the same name already exists");
        return;
      }

      const handleAddProject = (response) => {
        isDevEnvironment && console.log("Project created successfully");
        socket.removeEventListener("add_project", handleAddProject);
        if (response?.success === false) {
          setShowToastr({
            show: true,
            type: "error",
            text: response?.error
          });
        } else {
          var projectId = response.project_id ?? response;
          sessionStorage.setItem("project_id", projectId);
          isDevEnvironment && console.log("Project created successfully with id: " + projectId);
          var newProject = true;
          // socket.send("get_projects");
          socket.addEventListener("get_projects", handleGetProjects);
          navigate(`/canvas/${projectId}`, {
            state: { projectId, projectName, newProject, sandboxMode },
          });
        }
      };

      socket.addEventListener("add_project", handleAddProject);
      socket.send("add_project", {
        project_name: projectName,
        project_description: projectDescription // Optional field
      });

      // Clear the error message
      setErrorMessage("");
      setProjectName("");
      setProjectDescription("");
      // Close the modal
      handleClose();
    }
  };
  const handleKeyDown = (e) =>{
    if(projectName.trim() !== ""){
      if (e.key === "Enter") {
        handleCreateProject();
      }
    }
  }

  return (
    <ModalUI
      show={show}
      handleClose={() => {
        setErrorMessage("");
        setProjectName("");
        setProjectDescription("");
        handleClose();
      }}
      modalTitle={modalTitle}
      theme="dark"
      classes="border-no"
    >
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label className={styles["custom-label"]}>
              Project name
            </Form.Label>
            <Form.Control
              type="input"
              name="project_name"
              data-testid="project_name"
              placeholder="Enter a project name"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              className={styles["custom-input"]}
              onKeyDown={handleKeyDown}
            />
          </Form.Group>
          {errorMessage && (
            <div className={styles["error-message"]}>{errorMessage}</div>
          )}
          <Form.Group className="mb-3">
            <Form.Label className={styles["custom-label"]}>
              Project Description (Optional)
            </Form.Label>
            <Form.Control
              name="project_description"
              data-testid="project_description"
              as="textarea"
              rows={3}
              placeholder="Enter project description"
              value={projectDescription}
              onChange={(e) => setProjectDescription(e.target.value)}
              className={styles["custom-textarea"]}
            />
          </Form.Group>
          <button
            className={`${"blue-btn"} ${!projectName.trim() && "btn-disabled"}`}
            style={{ float: "right" }}
            onClick={handleCreateProject}
            disabled={!projectName.trim()}
          >
            <span>
              {" "}
              <TickSvg />
            </span>
            Create
          </button>
        </Col>
      </Row>
    </ModalUI>
  );
};

export default AddProjectModal;
