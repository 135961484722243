/**
 * This function return the Node and Edges Id's that need to be highlighted for Canvas Highlight flow
 */

export const getHighlightEdgePathFn = (nodes, graphEdges) => {
  if (Array.isArray(nodes) && nodes.length > 0) {
    const sourceNode = nodes[0];
    const targetNodes = nodes.slice(1);
    const visited = new Set();
    const path = [];
    const edges = [];
    const connectedEdges = [];

    const dfs = (nodeId, path, edges, visited) => {
      visited.add(nodeId);
      path.push(nodeId);

      targetNodes.map((el) => {
        if (nodeId === el) {
          connectedEdges.push(...edges);
          return true; // Found target
        }
      })

      const adjacentEdges = graphEdges.filter((element) => element.source === nodeId);
      for (const edge of adjacentEdges) {
        const nextNodeId = edge.target;
        if (!visited.has(nextNodeId)) {
          const newEdges = [...edges, edge]; // Copy existing edges and add current
          if (dfs(nextNodeId, [...path], newEdges, visited)) {
            return true; // Path found, propagate success back up
          }
        }
      }

      // Backtrack if path is not found
      path.pop();
      if (edges.length > 0) edges.pop();
      return false; // Path to target not found from this node
    };

    dfs(sourceNode, path, edges, visited);

    return { selectedNodes: nodes ?? [], selectedEdges: connectedEdges ?? [] };
  }
};
