/**
 *  This component use to create custom Node and attach associations to node
 */

import React, { useEffect, useState } from "react";
import { Handle } from "reactflow";
import styled from "styled-components";
import keyIcon from "../../../images/icons/key-icon.png";
import context from "../../../store/create-context";
import linkedNode from "../../../images/infraIcon_lightgreen.svg";
import { useContext } from "react";
import "../new-canvas/canvas.component.css";

// Separate styled components for node and horizontal bar
const StyledNode = styled.div`
  boxsizing: borderBox;
  width: 128px;
  height: 125px;
  border-radius: 8px;
  // position: absolute;
  z-index: 10px;
  background: #181818;
  ${(props) =>
    props.colorProp
      ? `border: ${props.borderWidthProp} solid ${props.colorProp}`
      : `1px solid #4e4e4e;`};
  ${(props) =>
        props.colorProp
          ? `background: ${props.backgroundProp}`
          : `#2f2f2f;`};
  &:hover {border: 2px solid rgb(0, 178, 217)}
`;

const StyledInnerNode = styled.div`
  boxsizing: borderBox;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 8px;
  padding: 1rem 1rem;
  text-align: center !important;
  background: inherit;
`;
// ${(props) =>
//   props.has_subgraph && `border: 3px solid ${props.colorProp ?? "#4e4e4e"};`}
const StyledHr = styled.hr`
  border-top: 1px solid white;
  width: 100%;
`;

function CloudIcon({
  data: {
    handles,
    label,
    IconComponent,
    violations,
    style,
    associations,
    has_subgraph,
    node_type,
  },
}) {
  const ctx = useContext(context);
  const [rename, setRename] = useState(false)
  const [renamedLabel, setRenamedLabel] = useState(label);
  const [tempLabel, setTempLabel] = useState(label);

  const handleChange = (event) => {
    setTempLabel(event.target.value);
  };

  const handleBlur = () => {
    setRenamedLabel(tempLabel);
    setRename(false);
  };

  useEffect(() => {
    ctx.setMatchedDetails((prev) => [...prev, label]);
  }, [])

  let borderColor = style.borderColor ?? "#232F3E";
  let borderWidth = style.borderWidth ?? "1px";
  let backgroundColor = style.backgroundColor ?? "#181818";
  // switch (violations) {
  //     case 1:
  //         borderColor = 'green';
  //         break;
  //     case 2:
  //         borderColor = 'red';
  //         break;
  //     default:
  //         borderColor = '#232F3E';
  // }

  function splitString(text, len) {
    let words = text?.split(" ");
    let result = [];
    let currentString = "";

    for (let i = 0; i < words?.length; i++) {
      let word = words[i];
      if (currentString.length + word.length + 1 <= len) {
        currentString += word + " ";
      } else {
        result.push(
          <React.Fragment key={result.length}>
            {currentString.trim()}
            <br />
          </React.Fragment>
        );
        currentString = word + " ";
      }
    }

    if (currentString.length > 0) {
      result.push(
        <React.Fragment key={result.length}>
          {currentString.trim()}
          <br />
        </React.Fragment>
      );
    }

    return result;
  }

  const display_node_type = (str) => {
    if (str?.includes(":")) {
      const newStr = str?.split(":")[1]?.slice(0, 8);
      return newStr;
    }
    if (str?.startsWith("AWS")) {
      const newStr = str?.split("AWS")[1].slice(0, 9);
      return newStr;
    }
    str = str?.slice(0, 8);
    return str;
  };

  return (
    <>
      {handles.map(({ id, type, position }) => (
        <Handle
          key={id}
          id={id}
          type={type}
          position={position}
          style={{ background: "#555"}}
          isConnectable={true}
        />
      ))}
      <div style={{wordBreak: "normal", "overflowWrap": "break-word"}}
        className={`label${label}_node ${ctx.nodeLabel.includes(label)
           || (ctx.showAddNewNodeModal && ctx.showAddNewNodeModal.data.label === label) ? "matched-node" : ""}`}
      >
        <div
          style={{
            color: "#FFFFFF",
            position: "absolute",
            marginLeft:
              display_node_type(node_type)?.length > 16 && !has_subgraph
                ? "1px"
                : "5px",
            whiteSpace:
              display_node_type(node_type)?.length > 18 ? "nowrap" : "normal",  
            display: "flex",
            // justifyContent: "center",
            marginTop: "5px",
            writingMode: "vertical-rl",
            height: "100%",
            // fontSize: "15px",
            fontSize: "11px",
            lineHeight: "12px"
          }}
        >
          {display_node_type(node_type)}
        </div>
        <StyledNode colorProp={borderColor} borderWidthProp={borderWidth} backgroundProp={backgroundColor}>
          <StyledInnerNode
            colorProp={borderColor}
            borderWidthProp={borderWidth}
            has_subgraph={has_subgraph}
          >
            <>
              <IconComponent
                size={30}
                style={{ marginBottom: "1px", borderRadius: "4px"}}
              />
              {ctx.linkedNode.map((node) =>
                node.data.label === label && (
                  <span style={{ position: 'absolute', right: '7px', top: '5px' }} key={node.jml_id}>
                    <img src={linkedNode} alt="linkedNode" style={{ width: '16px' }} />
                  </span>
                )
              )}

              {ctx.linkedNodeDetail.map((node) =>
                node.data.label === label && (
                  <span style={{ position: 'absolute', right: '7px', top: '5px' }} key={node.jml_id}>
                    <img src={linkedNode} alt="linkedNode" style={{ width: '16px' }} />
                  </span>
                )
              )}
              {/* <StyledHr className='icon-div-hr' /> */}
              <small
                style={{
                  // minHeight: '30px',
                  // fontSize: ctx.zoomVal <= 0.7 ? "1.3rem" : "0.875rem",
                  // width: ctx.zoomVal > 0.7 ? "13ch": "9ch",
                  // textOverflow: ctx.zoomVal <= 0.7 && "ellipsis",
                  // overflow: ctx.zoomVal <= 0.7 && "hidden",
                  // whiteSpace: ctx.zoomVal <= 0.7 && "nowrap",
                  // marginTop: '1px',
                  fontSize: "1rem",
                  width: "13ch",
                  fontWeight: "400",
                  padding: '1px',
                  textAlign: "center",
                  color: "#FFFFFF",
                  lineHeight: "16px",
                  wordWrap: "break-word", // Ensure long words break
                  whiteSpace: "normal", // Allow text to wrap
                  // overflowWrap: "normal", // Alternative to wordWrap for better support
                  // width: display_lable(label)?.length > 16 && "90px",
                }}
              >
                {rename ? (
                  <span>
                    <input
                      type="text"
                      value={tempLabel}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoFocus
                      style={{ width: "110px", background:'#0c0c0c', color:'#FFFFFF', borderRadius:'4px'}}
                    />
                  </span>
                ) : (
                  <span onClick={(e) =>{
                    e.stopPropagation()
                    setRename(true)
                    }}>{renamedLabel}</span>
                )}
              </small>
              {associations !== undefined && (
                <>
                  <div
                    className="position-absolute d-flex flex-row gap-1 "
                    style={{ bottom: "-10%", left: "-7%" }}
                  >
                    {associations.map((association) => {
                      const firstLetter =
                        association.data?.label?.charAt(0)?.toUpperCase() ||
                        "S";
                      return (
                        <>
                          {/* <img src={keyIcon} 
                                        className='association-element'
                                        data-id={association.id}
                                    /> */}
                          <div
                            className="association-element d-flex align-items-center justify-content-center rounded-circle z-1 fw-bold text-white "
                            data-id={association.id}
                            style={{
                              background: "blue",
                              width: "30px",
                              height: "30px",
                            }}
                          >
                            {firstLetter}
                          </div>
                        </>
                      );
                    })}
                  </div>
                  <div
                    className="position-absolute d-flex flex-column gap-1 "
                    style={{
                      bottom: "-50%",
                      color: "#fff",
                      fontSize: "12px",
                      fontWeight: 600,
                    }}
                  >
                    {associations.map((association) => {
                      return <span>{association.rid ?? ""}</span>;
                    })}
                  </div>
                </>
              )}
            </>
          </StyledInnerNode>
        </StyledNode>
        <div className = {`${has_subgraph && "parent-div"}`}>
          </div>
      </div>
    </>
  );
}

export default CloudIcon;
