import { useRef, useState, useEffect } from "react";
import { Overlay } from "react-bootstrap";
import Popover from "react-bootstrap/Popover";
import CloseIcon from "../../../../../images/icons/close";
import AWSManagementConsoleIcon from "../../../../../images/aws/Resource-Icons/Res_General-Icons/Res_48_Dark/Res_AWS-Management-Console_48_Dark.svg";
import AWSGlobeIcon from "../../../../../images/aws/Resource-Icons/Res_General-Icons/Res_48_Dark/Res_Globe_48_Dark.svg";
import WebSocketInstance from "../../../../../common/WebSocketInstance";
import "./ResourceIconPopUp.css";

const ResourceIconPopUp = ({
  target,
  showPopUp,
  setShowPopUp,
  setSelectedResource,
  setOpenAccordionForItem,
  openAccordionForItem,
  selectedResource,
  placement = "right",
  maxWidth = "35%",
  flip = true,
  additionalInfo,
  setAdditionalInfo,
  selectedDropDownValues,
  socket = WebSocketInstance.getInstance(),
}) => {
  const ref = useRef();
  const [errorMessage, setErrorMessage] = useState("");
  const isDevEnvironment = process.env.REACT_APP_ENVIRONMENT === "dev";
  const [url,setUrl] = useState({})

  useEffect(() => {
    isDevEnvironment && console.log("Component mounted or socket changed");

    if (socket) {
      socket.addEventListener("cloud_aws_get_additional_info", handleAdditionalInfoResponse);
      isDevEnvironment && console.log("cloud_aws_get_additional_info event listener added");
    }

    return () => {
      if (socket) {
        socket.removeEventListener("cloud_aws_get_additional_info", handleAdditionalInfoResponse);
        isDevEnvironment && console.log("cloud_aws_get_additional_info event listener removed");
      }
    };
  }, [socket]);

  useEffect(() => {
    isDevEnvironment && console.log("Component mounted or socket changed for URL");

    if(socket){
      try {
        socket.send("get_aws_urls",{
          project_id: sessionStorage.getItem("project_id"),
          account_id: selectedDropDownValues["aws:account"]?.id,
          region: selectedDropDownValues["aws:region"].name,
          resource_id: selectedResource !== "" ? selectedResource.data.resource_id : openAccordionForItem.data.resource_id,
          resource_name: selectedResource !== "" ? selectedResource.name : openAccordionForItem.name,
          node_type: selectedResource !== "" ? selectedResource.type : openAccordionForItem.type,
          account_region: selectedDropDownValues["aws:region"]?.id
        })
      } catch (error) {
        console.error("Failed to send request: ", error);
      }
      socket.addEventListener("get_aws_urls", handleResponseForUrl);
      isDevEnvironment && console.log("get_aws_urls event listener added");
    }

    return () => {
      if (socket) {
        socket.removeEventListener("get_aws_urls", handleResponseForUrl);
        isDevEnvironment && console.log("get_aws_urls event listener removed");
      }
    };
  },[selectedResource]);

  const closePopUp = () => {
    setShowPopUp(false);
    setSelectedResource("");
  };
  const handleAdditionalInfoResponse = (response) => {
    isDevEnvironment && console.log("Received response: ", response);

    if (response?.success) {
      if (response.additional_info && Object.keys(response.additional_info).length > 0) {
        setAdditionalInfo(response.additional_info);
        isDevEnvironment && console.log("Additional info: ", response.additional_info);
      } else {
        setAdditionalInfo("Couldn't get more info for this resource. Please try some other resource or reach out to info@jigsawml.com");
      }
    } else {
      setAdditionalInfo("An error occurred: " + response.error || "An error occurred while fetching additional info.");
      console.error("Error: ", response.error);
    }
  };

  const handleResponseForUrl = (response) => {
    if(response.success){
      delete response.success;
      setUrl(response);
    }else {
      console.log(response.error);
    }
  };

  const getAdditionalInfo = (e) => {
    e.preventDefault();
    isDevEnvironment && console.log("Attempting to send request for additional info");

    if (socket && typeof socket.send === 'function') {
      try {
        socket.send("cloud_aws_get_additional_info", {
          project_id: sessionStorage.getItem("project_id"),
          node_type: selectedResource !== "" ? selectedResource.type : openAccordionForItem.type,
          resource_name: selectedResource !== "" ? selectedResource.name : openAccordionForItem.name,
          resource_id: selectedResource !== "" ? selectedResource.data.resource_id : openAccordionForItem.data.resource_id,
          account_id: selectedDropDownValues["aws:account"]?.id,
          account_region: selectedDropDownValues["aws:region"]?.id
        });
      } catch (error) {
        setErrorMessage("Failed to send request: " + error.message);
        console.error("Failed to send request: ", error);
      }
    } else {
      setErrorMessage("Socket connection is not available. Please try again later.");
      console.error("Socket connection is not available");
    }
  };

  // replacer function
  const dataSanitizer = (key, value) => {
    // 1. Exclude keys with null values
    if (value === null) {
      return undefined;
    }

    // 2. Parse embedded JSON strings
    if (typeof value === "string" && value.trim().startsWith("{")) {
      try {
        return JSON.parse(value); // Parse and return as object
      } catch {
        return value; // Return original string if parsing fails
      }
    }

    return value; // Return unchanged for all other values
  };

  return (
    showPopUp && (
      <div className="res-pop-up" ref={ref}>
        <Overlay
          show={showPopUp}
          target={target}
          placement={placement}
          container={ref}
          flip={flip}
          onHide={closePopUp}
          rootClose
        >
          <Popover
            id="res-pop-up"
            style={{
              minHeight: "200px",
              maxHeight: "500px",
              minWidth: "250px",
              maxWidth: `${maxWidth}`,
              borderRadius: "4px !important",
              backgroundColor: "rgb(18,20,31)",
              border: "1px solid #696969",
              padding: "0.5rem 0.75rem",
              background: "rgb(18,20,31)",
              marginBottom: "-32px",
              zIndex:'9999',
              wordWrap: "break-word",
              overflowWrap: "break-word",
              wordBreak: "break-word",
              overflow: "auto",
            }}
          >
            <Popover.Body>
              <div className="resource-icon-popUp">
                <div className="res-i-pop-up">
                  <span className="res-detail"><strong>Resource Name:</strong> {selectedResource !== "" ? selectedResource.name : openAccordionForItem.name}</span>
                  <span className="res-detail"><strong>Resource ID:</strong> {selectedResource !== "" ? selectedResource.data.resource_id : openAccordionForItem.data.resource_id}</span>
                  <span className="res-detail"><strong>Resource Type:</strong> {selectedResource !== "" ? selectedResource.type : openAccordionForItem.type}</span>
                  <span className="res-detail"><strong>Service Description:</strong> {selectedResource !== "" ? selectedResource.description : openAccordionForItem.description}</span>
                  <span className="res-detail">
                    <strong>URLs:</strong>{" "}
                    {[
                      { url: url?.resource_info_url, label: "Resource Info" },
                      { url: url?.resource_list_url, label: "Resource List" },
                      { url: url?.service_dashboard_url, label: "Service Dashboard" },
                      { url: url?.service_info_url, label: "Service Info" },
                    ]
                      .filter(link => link.url) // Remove items where the URL is empty
                      .map((link, index, arr) => (
                        <>
                          <a href={link.url} className="res-url" target="_blank" rel="noreferrer">
                            <img
                              src={link.label === "Service Info" ? AWSGlobeIcon : AWSManagementConsoleIcon}
                              alt="AWS Icon"
                              style={{ width: "20px", height: "20px", marginRight: "5px", verticalAlign: "middle" }}
                            />
                          {link.label}
                          </a>
                          {index < arr.length - 1 ? ", " : ""}
                        </>
                      ))}
                  </span>
                  {additionalInfo === null ? (
                    <a href="#" className="res-link" onClick={getAdditionalInfo}>
                      Fetch More Info
                    </a>
                  ) : (
                    <div className="res-detail"><strong>Resource Info:</strong> <pre style={{ display: 'inline' }}>{JSON.stringify(additionalInfo, dataSanitizer, 2)}</pre></div>
                  )}
                  {errorMessage && <div className="error-message">{errorMessage}</div>}
                </div>
                <span onClick={closePopUp}>
                  <CloseIcon />
                </span>
              </div>
            </Popover.Body>
          </Popover>
        </Overlay>
      </div>
    )
  );
};

export default ResourceIconPopUp;
