import React, { useEffect, useState, useRef, useContext } from "react";
import { Nav, Dropdown, Offcanvas, OverlayTrigger, Tooltip } from "react-bootstrap";
import context from "../../../store/create-context";
import { stringUrl } from "../../../constants";
import LeftSideActiveLog from "./LeftSideActiveLog";
import {
  CrossSvg,
  SearchSvg,
  CollpaseSvg,
  SummarySvg,
  DescriptionSvg,
  ComponentSvg,
  PropConsSvg,
  SliderSvg,
  AllSvg,
  DataFlowSvg,
  DownSvg,
  UpSvg,
  ActivityLogSvg,
  StartSvg
} from "../../../images/svg/icons";
import { Popover as ReactBootstrapPopover } from "react-bootstrap";
import styles from "./LeftSidebar.module.css";
import { timeSpent } from "../../../utils/google-analytics";
import expandBtn from "../../../images/expandbtn.svg";

const LeftSidebar = ({ sidebarData }) => {
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  //Variables
  let interval = null;
  const activeButtonStyle = {
    backgroundColor: "blue",
    color: "white",
  };
  const regularButtonStyle = {};
  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.5,
  };

  //context
  const {
    setHightlightEdgesContext,
    setHightlightNodesContext,
    searchNode,
    setSearchNode,
    setFooterSearchText,
    showDocSidebarCanvas,
    setDocSidebarCanvas,
    userId,
    setQueryStr,
  } = useContext(context);

  useEffect(() => {
    if(showDocSidebarCanvas ===  true) {
      const timeStart = new Date().getTime()
      setStartTime(timeStart);
    }

     if(showDocSidebarCanvas ===  false ) {
      const timeEnd = new Date().getTime();
      setEndTime(timeEnd);
      let timeDifferenceMS = timeEnd - startTime;
      var msPerMinute = 60 * 1000;
      var msPerHour = msPerMinute * 60;
      var msPerDay = msPerHour * 24;
      let duration;
  if (timeDifferenceMS < msPerMinute) {
     duration =  Math.round(timeDifferenceMS/1000)+' seconds' ;
  }
  else if (timeDifferenceMS < msPerHour) {
    duration =  Math.round(timeDifferenceMS/msPerMinute) + ' minutes';
  }
  else if (timeDifferenceMS < msPerDay ) {
    duration =  Math.round(timeDifferenceMS/msPerHour ) + ' hours';
  }
      duration && timeSpent(duration, "Analysis Pane", userId);
     }
  },[showDocSidebarCanvas]);

  //Ref
  const searchInputRef = useRef();

  //states
  const [activeTab, setActiveTab] = useState("");
  const [navData, setNavData] = useState(null);
  const [activityLogData, setActivityLogData] = useState(null);
  const [activeButton, setActiveButton] = useState(null);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [searchCountAll, setSearschCountAll] = useState([
    {
      id: "total",
      label: "All",
      icon: <AllSvg />,
      current: 0,
      countTotal: 0,
    },
    {
      id: "summary",
      label: "Summary",
      icon: <SummarySvg />,
      current: 0,
      countTotal: 0,
    },
    {
      id: "description",
      label: "Description",
      icon: <DescriptionSvg />,
      current: 0,
      countTotal: 0,
    },
    {
      id: "components",
      label: "Components",
      icon: <ComponentSvg />,
      current: 0,
      countTotal: 0,
    },
    {
      id: "dataFlow",
      label: "Data flow",
      icon: <DataFlowSvg />,
      current: 0,
      countTotal: 0,
    },
    {
      id: "prosCons",
      label: "Pros/Cons",
      icon: <PropConsSvg />,
      current: 0,
      countTotal: 0,
    },
  ]);
  /*Observer*/
  const callback = (entries) => {
    const currentlyVisibleElements = entries
      .filter((entry) => entry.isIntersecting)
      .map((entry) => entry.target);

    if (currentlyVisibleElements.length > 0) {
      let _ele = currentlyVisibleElements[0];
      if (_ele.id != "") {
        setActiveTab(_ele.id);
      }
    }
  };
  const observer = new IntersectionObserver(callback, options);

  //Effects

  useEffect(() => {
    interval = setTimeout(() => {
      let tabs = [
        "summary",
        "description",
        "components",
        "dataFlow",
        "pros",
        "cons"
      ];
      let allTabData = [];
      tabs.forEach((tab) => {
        let data = getTabContent(tab);
        allTabData.push(data);
      });

      if (allTabData.length > 0) setNavData(allTabData);

    }, 100);

    return () => {
      clearTimeout(interval);
    };
  }, [sidebarData]);

  useEffect(() => {
    interval = setTimeout(() => {
      if (showDocSidebarCanvas) {
        // Observe all target elements
        document.querySelectorAll(".observerElement").forEach((element) => {
          if (element) {
            observer.observe(element);
          }
        });
      }
    }, 1000);

    if(!showDocSidebarCanvas){
      setActiveButton(null)
    }

    return () => {
      clearTimeout(interval);
      observer.disconnect();
    };
  }, [showDocSidebarCanvas, activeTab]);

  useEffect(() => {
    if (!showSearchBar) {
      setSearchNode(null);
    } else if (
      searchNode != "" &&
      searchNode != null &&
      searchInputRef.current &&
      searchInputRef.current.value != searchNode
    ) {
      searchInputRef.current.value = searchNode;
      countSearchAll(searchNode);
    }
  }, [searchNode]);

  //Handlers

  const showSearchBarHandler = () => {
    if (showSearchBar) {
      clearSearchInput();
      setSearchNode(null);
    }
    setShowSearchBar(!showSearchBar);
  };

  const countSearchAll = (searchStr) => {
    clearTimeout(interval);
    interval = setTimeout(() => {
      let searchCountData = searchCountAll;
      searchCountData = searchCountData.map((ele) => ({
        ...ele,
        current: 0,
        countTotal: 0,
      }));
      if (searchStr != "") {
        searchCountData.forEach(function (ele, index) {
          if (ele.id != "total") {
            let c = 0;
            let content = getTabContent(ele.id)["data"];
            if (content.length > 0) {
              content.forEach(function (text) {
                const parts = text.split(new RegExp(`(${searchStr})`, "gi"));
                parts.forEach(function (txt) {
                  txt.toLowerCase() === searchStr.toLowerCase() && ++c;
                });
              });
            }
            searchCountData[index]["current"] = 0;
            searchCountData[index]["countTotal"] = c;
            searchCountData[0]["countTotal"] += c;
          }
        });
      }

      setSearchNode(null);

      if (searchCountData[0]["countTotal"] > 0) {
        searchCountData[0]["current"] = 1;
      }
      setSearschCountAll(searchCountData);
    }, 1000);
  };

  const clearSearchInput = () => {
    if (searchInputRef.current) {
      searchInputRef.current.value = "";
      countSearchAll("");
    }
  };

  const documentToggleHandler = () => {
    if (showSearchBar) {
      showSearchBarHandler();
    }
    if (activeTab != "") {
      setActiveTab("");
    }
    setDocSidebarCanvas((prev) => !prev);
  };

  const documentToggleHandlerForSideTab = () => {
    if (showSearchBar) {
      showSearchBarHandler();
    }
    if (activeTab != "") {
      setActiveTab("");
    }
    setDocSidebarCanvas(() => true);
  };

  const documentCloseHandler = () => {
    setDocSidebarCanvas(false);
    // setActiveTab("");
  };

  const setActiveTabHandler = (value) => {
    /*set active tab*/
    setActiveTab(value);

    if (value == "activitylog" && showSearchBar) {
      showSearchBarHandler();
    }

    if (activeTab == value) {
      documentToggleHandler();
    }
    if (activeTab == "") {
      documentToggleHandlerForSideTab();
    }

    setTimeout(() => {
      let target = document.getElementById(value);
      if (target) scrollIntoViewIfVisible(target);
    }, 200);
  };

  const getNewPros= (pros) => {
    const updatedPros = pros?.map(
      (pro, index) =>
        `<div style="display: flex;"><div style="margin-right: 10px;"> ${
          index + 1
        }</div><div>${pro}</div></div>`
    );
    return updatedPros;
  };

  const getNewCons = (cons) =>{
    const updatedCons = cons?.map (
      (con) => `${con}`
    )
    return updatedCons;
  }

  const getTabContent = (tabName) => {
    let markup = [];
    let arr = [];
    let consArr = [];
    let services = [];
    let label = "";
    let dataflow = [];
    let activitylog = {
      actions: [],
      uploads: [],
      connections: [],
    };

    if (tabName === "summary") {
      arr = sidebarData["summary_markup"]
        ? sidebarData["summary_markup"]["text"]
        : sidebarData["summary"] ?? [];
      markup = sidebarData["summary_markup"]
        ? sidebarData["summary_markup"]["markup"]
        : [];
      label = "Summary:";
    }

    if (tabName === "description") {
      arr = sidebarData["arch_detail_markup"]
        ? sidebarData["arch_detail_markup"]["text"]
        : sidebarData["arch_detail"] ?? [];
      markup = sidebarData["arch_detail_markup"]
        ? sidebarData["arch_detail_markup"]["markup"]
        : [];
      label = "Description:";
    }

    if (tabName === "components") {
      services = sidebarData["services"] ?? [];
      label = "Services used:";
    }

    if (tabName === "dataFlow") {
      arr = sidebarData["connection_lines"] ?? [];
      label = "Data flow:";
      dataflow = sidebarData["dataflow"]
        ? Array.isArray(sidebarData["dataflow"])
          ? sidebarData["dataflow"].filter((ele) => ele.name || ele.path || ele.description)
          : []
        : [];
    }

    if (tabName === "pros") {
      let pros =
        sidebarData["pros_cons"] && (sidebarData["pros_cons"]["pros"] ?? []);
      if (!Array.isArray(pros)) {
        pros = [];
      }
      arr = getNewPros(pros);
      label = "Pros:";
    }

    if(tabName === "cons"){
      let cons =
        sidebarData["pros_cons"] && (sidebarData["pros_cons"]["cons"] ?? []);
      if (!Array.isArray(cons)) {
        cons = [];
      }
      consArr = getNewCons(cons);
      label = "Cons:";
    }

    if (tabName == "activitylog") {
      let logs = sidebarData["activity_log"];
      if (Array.isArray(logs) && logs.length > 0) {
        logs.forEach((log) => {
          if (log.activity_type.toLowerCase() == "actions") {
            activitylog["actions"].push(log);
          }
          if (log.activity_type.toLowerCase() == "uploads") {
            activitylog["uploads"].push(log);
          }
          if (log.activity_type.toLowerCase() == "connections") {
            activitylog["connections"].push(log);
          }
        });
      }
    }

    if (!Array.isArray(arr)) {
      arr = [arr];
    }
    if (!Array.isArray(consArr)) {
      consArr = [consArr];
    }
    if (!Array.isArray(services)) {
      services = [services];
    }

    let nodesTobeHighlight = [];
    if (sidebarData.nodes && Array.isArray(sidebarData.nodes)) {
      nodesTobeHighlight = sidebarData.nodes.map((node) => {
        return { nodeId: node.id, nodeName: node.name };
      });
    }
    let nodesName = [];
    if (sidebarData.nodes && Array.isArray(sidebarData.nodes)) {
      nodesName = sidebarData.nodes.map((node, index) => {
        return {key: index, name: node.name};
      });
    }
    markup = [
      ...markup,
      { type: "nodes_highlight", text: nodesName, nodesTobeHighlight },
    ];
    return {
      tabName,
      label,
      data: arr,
      consArr,
      markup,
      dataflow,
      services,
      activitylog,
    };
  };

  function moveToText(direction) {
    let { current, countTotal } = searchCountAll[0];
    if (countTotal > 0) {
      if (direction == "up") {
        current -= 1;
      }
      if (direction == "down") {
        current += 1;
      }
      if (current < 1) {
        current = countTotal;
      }
      if (current > countTotal) {
        current = 1;
      }
      const _targetEle = document.getElementById(`text-${current}`);
      document.querySelectorAll(".highlight-text").forEach((ele) => {
        ele.style.backgroundColor = "#ffffff";
      });
      if (_targetEle) {
        _targetEle.style.backgroundColor = "#ffa500";
        scrollIntoViewIfVisible(_targetEle);
      }

      setSearschCountAll((prevSearchCountAll) => [
        {
          ...prevSearchCountAll[0],
          current,
        },
        ...prevSearchCountAll.slice(1),
      ]);
    }
  }

  /**
   * Attach Mark Up Events to the string
   */

  const renderHighlightedText = (str, markUpArr, sectionLabel) => {
    //Render Text
    const renderText = (text, index, sectionLabel) => {
      if (searchInputRef.current && searchInputRef.current.value != "") {
        text = getHighlightedText(text, searchInputRef.current.value);
      }
      text = transformString(text, sectionLabel);
      text = text.replace(/\.\s/g, ".&nbsp;");
      return <span key={index} dangerouslySetInnerHTML={{ __html: text }} />;
    };

    //Render PopUp
    const renderPopover = (text, popupText, index) => {
      const popover = (
        <ReactBootstrapPopover
          id="popover-basic"
          style={{ background: "rgb(47, 47, 47)" }}
          key={index}
        >
          <ReactBootstrapPopover.Body>
            <p className="p-2">{popupText}</p>
          </ReactBootstrapPopover.Body>
        </ReactBootstrapPopover>
      );

      return (
        <OverlayTrigger
          trigger="click"
          placement="right"
          overlay={popover}
          rootClose
        >
          <span
            variant="success"
            style={{ color: "#02DC59", cursor: "pointer" }}
          >
            {text}
          </span>
        </OverlayTrigger>
      );
    };

    //Render JmlSearch
    const renderJmlSearch = (text, jmlSearch, index) => (
      <span
        key={index}
        style={{ color: "yellow", cursor: "pointer" }}
        onClick={() => setFooterSearchText(jmlSearch)}
      >
        {text}
      </span>
    );

    //Render Canvas HighLight
    const renderCanvasHighlight = (text, canvasHighlight, index) => (
      <span
        key={index}
        style={{ color: "black", backgroundColor: "lightgray", cursor: "pointer" }}
        onClick={() => setHightlightNodesContext(canvasHighlight)}
      >
        {text}
      </span>
    );

    //Render nodes highlights
    const renderNodesHighlight = (text, nodesTobeHighlight, index) => {
      let highlightNode = nodesTobeHighlight.find((el) => el.nodeName == text);
      return (
        <div>
        <strong
          key={index}
          className={`${sectionLabel == "Pros:"? styles["text-node-hightlight"]: ""}`}
          style={{ cursor: "pointer", textDecoration: "underline"}}
          onClick={() => setHightlightNodesContext([highlightNode.nodeId])}
          >
          {text}
        </strong>
        <strong
          key={index}
          className={`${sectionLabel == "Cons:" && styles["text-node-hightlight"]}`}
          style={{ cursor: "pointer", textDecoration: "underline"}}
          onClick={() => setHightlightNodesContext([highlightNode.nodeId])}
          >
          {text}
        </strong>
        </div>
      );
      };
    const renderAnchorTag = (text, href, index) => (
      <a
      key={index}
      href={href}
      target="_blank"
      style={{ color: "#0078D4", opacity: "0.7" }}
      >
        {text}
      </a>
    );

    //Render Hover
    const renderHover = (text, hoverText, index) => (
      <span
        key={index}
        data-toggle="tooltip"
        data-placement="bottom"
        title={hoverText}
        style={{
          color: "#0000FF",
          textDecoration: "underline",
          cursor: "pointer",
        }}
      >
        {text}
      </span>
    );

    //Render Markup
    const renderMarkup = (text, data, index) => {
      let type = data.type;
      switch (type) {
        case "href":
          return renderAnchorTag(text, data.href, index);
        case "hover":
          return renderHover(text, data.hover_text, index);
        case "popup":
          return renderPopover(text, data.popup_text, index);
        case "jml_search":
          return renderJmlSearch(text, data.jml_search, index);
        case "canvas_highlight":
          return renderCanvasHighlight(text, data.canvas_highlight, index);
        case "nodes_highlight":
          return renderNodesHighlight(text, data.nodesTobeHighlight, index);
        default:
          return renderText(text, index, sectionLabel);
      }
    };

    /*split string based on wordsToSplit array*/
    let wordsToSplit = new Set();

    /**
     * Get All Nodes Names and id
     */
    let nodesArr = [];
    if (sidebarData.nodes && Array.isArray(sidebarData.nodes)) {
      nodesArr = sidebarData.nodes.map((node) => {
        //  wordsToSplit.add(node.name)
        return { nodeId: node.id, nodeName: node.name };
      });
    }

    /**
     * return str if markUpArr is empty
    **/
    if (markUpArr.length == 0) {
      return renderText(str, Math.random(), sectionLabel);
    }

    if (markUpArr && Array.isArray(markUpArr)) {
      markUpArr.forEach((item) => {
        if (item.text && Array.isArray(item.text)) {
          item.text.forEach((el) => {
            wordsToSplit.add(el.name);
          });
        } else if (item.text) wordsToSplit.add(item.text.trim());
      });
    }
    
    // TODO: This has an issue that hyperlinks will be marked everytime the corresponding aws service appears in the text.
    // One possible fix is to incorporate the aws services as well into the wordsToSplit set. 
    // Then, we can check if the word is an aws service or a canvas highlight and mark is appropriately.

    const regex = new RegExp(`(\\b${Array.from(wordsToSplit).join("\\b|\\b")}\\b)`);
    const parts = str.split(regex).filter((ele) => ele !== undefined);

    const modifiedStr = str.replace(regex, (match) => `${match}`);
    const replacedParts = new Set();

    const renderedText = renderText(modifiedStr, Math.random(), sectionLabel);
    const renderedParts = parts.map((part, index) => {
      const matchingMarkUp = markUpArr.find((markup) => {
        if (Array.isArray(markup.text)) 
          return markup.text.includes(part);
        return markup.text === part;
      });

      if (matchingMarkUp && !replacedParts.has(part)) {
        replacedParts.add(part);
        return renderMarkup(part, matchingMarkUp, index);
      }
      return null;
    });

    return (
      <>
      {renderedText}
      {renderedParts.filter(part => part !== null)}
      </>
    );
  };

  //Functions
  let c = 0;
  function getHighlightedText(text, highlight) {
    if (highlight != "") {
      let pattern = new RegExp(
        "(?!<a[^>]*?>)(" + highlight + ")(?![^<]*?</a>)",
        "gi"
      );
      text = text.replace(pattern, function (match, group) {
        ++c;
        return (
          `<span className="highlight-text" style='color:#444444;background-color: ${
            c == 1 ? "#ffa500" : "#ffffff"
          };' id="text-${c}">` +
          group +
          "</span>"
        );
      });

      let regex = new RegExp("<a.*?>.*?(" + highlight + ").*?</a>", "gi");
      text = text.replace(regex, function (match, p1) {
        return match.replace(
          p1,
          `<span className="highlight-text" style='color:#444444;background-color: ${
            c == 1 ? "#ffa500" : "#ffffff"
          };' id="text-${c}">${p1}</span>`
        );
      });
    }
    return text;
  }
  let hyperlinkObj = {
    Summary: [],
    Description: [],
    Pros: [],
    "Services used": [],
  };

  function transformString(inputString, sectionLabel, replace = true) {
    const secLabel = sectionLabel.substring(0, sectionLabel.length - 1);

    // Check if a substring matches any of the labels and replace with hyperlink
    if (!replace) {
      return inputString;
    }

    for (const item of stringUrl) {
      const {label, url} = item;
      const regex = new RegExp(`(?<!\w|[@!#$%*^&+~-])\\b${label}\\b(?!\w|[@!#$%*^&+~-])`);

      // We want to hyperlink only the first occurrence of each word so need to keep track of that part.
      let replaced = false;

      inputString = inputString.replace(regex, (match) => {
        if (replaced) {
          return match;
        }
        replaced = true;

        const {label, url} = item;
        if (!hyperlinkObj[secLabel].includes(match)) {
          return `<a href="${url}" target="_blank" style="color:#FFFFFF !important;opacity: 0.7;">${match}</a>`;
        } else {
          return `<span style="color:#ffffff">${match}</span>`;
        }
      })
    }
    return inputString;
  }

  const handleClick = async (text) =>{
    try {
      await navigator.clipboard.writeText(text);
      setQueryStr(text + " How to fix the issue?");
    }catch (err) {
      console.error("Failed to copy", err);
    }
  };

  const getAllDetailAnalysisSideData = (nav) =>
    {
      return (
        <>
          {nav.data.map((item, idx) => (
            <div
              className={`${nav.label === "Pros:" ? styles["text-pros"] : ''}`}
              key={idx + Math.random()}
            >
              {item.length > 0 && renderHighlightedText(item, nav.markup, nav.label)}
            </div>
          ))}
          {nav.consArr.map((item, idx) => (
            <div
              className={`${nav.label === "Cons:" ? styles["text-cons"] : ''}`}
              key={idx + Math.random()}
            >
              <span>{idx + 1}</span>
              <div className={styles["cons-item"]}>{item}</div>
              <div className={styles["cons-askai-btn"]} onClick={() => handleClick(item)}><StartSvg/></div>
            </div>
          ))}
        </>
    )}
  return (
    <>
      <div
        className={`${styles["left-cus-sidebar"]} ${
          showDocSidebarCanvas ? styles["sidebar-open"]: ""
        }`}
      >
        <Nav
          variant="pills"
          className={`flex-column ${styles["left-cus-sidebar-nav"]} h-100`}
        >
          <Nav.Item
            style={
              showDocSidebarCanvas && activeTab !== "activitylog"
                ? { width: "200px", background: "#2f2f2f", minHeight: "70px" }
                : {}
            }
          >
            <Nav.Link
              href=""
              onClick={() => {
                showDocSidebarCanvas && activeTab !== "activitylog"
                  ? documentCloseHandler()
                  : setActiveTabHandler("summary");
              }}
              className={`opacity-100 fw-bolder ${
                showDocSidebarCanvas &&
                activeTab !== "activitylog" ?
                "d-flex justify-content-center" : ""
              }`}
              style={
                showDocSidebarCanvas && activeTab !== "activitylog"
                  ? { fontSize: "14px" }
                  : {}
              }
            >
              {showDocSidebarCanvas && activeTab !== "activitylog" ? (
                <span>Detailed Analysis</span>
              ) : (
                <>
                
                  <span className={styles["analysisTab"]}>Analysis</span>
                </>
              )}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              href=""
              className={activeTab == "summary" ? styles["active"]: ""}
              onClick={() => {
                setActiveTabHandler("summary");
              }}
            >
              <span>
                <SummarySvg />
              </span>
              <span>Summary</span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              href=""
              className={activeTab == "description" ? styles["active"]: ""}
              onClick={() => {
                setActiveTabHandler("description");
              }}
            >
              <span>
                <DescriptionSvg />
              </span>
              <span>Description</span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              href=""
              className={activeTab == "components" ? styles["active"]: ""}
              onClick={() => {
                setActiveTabHandler("components");
              }}
            >
              <span>
                <ComponentSvg />
              </span>
              <span>Services</span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              href=""
              className={activeTab == "dataFlow" ? styles["active"]: ""}
              onClick={() => {
                setActiveTabHandler("dataFlow");
              }}
            >
              <span>
                <DataFlowSvg />
              </span>
              <span>DataFlows</span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="d-flex">
            <Nav.Link
              href=""
              className={`${activeTab == "pros" ? styles["active"]: ""} ${styles["pros-cons"]}`}
              onClick={() => {
                setActiveTabHandler("pros");
              }}
            >
              <span>
                <PropConsSvg />
              </span>
              <span>Pros/Cons</span>
            </Nav.Link>
            {!showDocSidebarCanvas && (
              <button
                className={styles["expand-btn"]}
                onClick={() => {
                  setActiveTabHandler(activeTab)
                }}
              >
                {/* <ExpandSvg />  */}
                <img src = {expandBtn} />
              </button>
            )}
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              href=""
              className={`${activeTab == "activitylog" ? styles["active"]: ""} ${styles["act-log-btn"]}`}
              onClick={() => {
                setActiveTabHandler("activitylog");
              }}
              // style={{
              //   border: "1px solid #5A46FF",
              //   background: "#5A46FF",
              // }}
            >
              <span>
                <ActivityLogSvg />
              </span>
              <span>Activity Log</span>
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
      <Offcanvas
        show={showDocSidebarCanvas}
        backdrop={false}
        onHide={documentToggleHandler}
        className={styles["custom-offcanvas"]}
      >
        {activeTab !== "activitylog" && (
          <Offcanvas.Header className="p-0 w-100">
            <Offcanvas.Title className="w-100">
              <div className={styles["expand-wrapper"]}>
                <div className={styles["expand-des"]}>
                  <Dropdown show={showSearchBar}>
                    <Dropdown.Toggle variant="" onClick={showSearchBarHandler}>
                      <SearchSvg />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className={styles["sidebar-search-container"]}
                    >
                      <div className={`px-3 d-flex align-items-center gap-3`}>
                        <span>
                          <SearchSvg />
                        </span>
                        <input
                          type="text"
                          placeholder="Search..."
                          className={styles["sidebar-search-input"]}
                          onKeyUp={(e) => {
                            countSearchAll(e.target.value);
                          }}
                          ref={searchInputRef}
                        />
                        <Dropdown>
                          <Dropdown.Toggle variant="">
                            <SliderSvg />
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            className={styles["search-result-dropdown-result"]}
                          >
                            {searchCountAll &&
                              searchCountAll.length > 0 &&
                              searchCountAll.map((item, index) => (
                                <Dropdown.Item
                                  key={index + Math.random()}
                                  onClick={() => {
                                    item.id != "total" &&
                                      setActiveTabHandler(item.id);
                                  }}
                                >
                                  <div>
                                    {item.icon}
                                    <span>{item.label}</span>
                                  </div>
                                  <span>{item.countTotal}</span>
                                </Dropdown.Item>
                              ))}
                          </Dropdown.Menu>
                        </Dropdown>
                        <button
                          className={styles["btn-remove-default-style"]}
                          onClick={showSearchBarHandler}
                        >
                          <CrossSvg />
                        </button>
                      </div>
                      <hr className={styles["custom-hr"]} />

                      <div className="px-4 py-1 d-flex justify-content-between text-white ">
                        <span className="opacity-50 fw-normal ">
                          {searchCountAll[0]["current"]} of{" "}
                          {searchCountAll[0]["countTotal"]} results
                        </span>
                        <div className={styles["up-down-arrow"]}>
                          <button onClick={() => moveToText("up")}>
                            <UpSvg />
                          </button>
                          <button onClick={() => moveToText("down")}>
                            <DownSvg />
                          </button>
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </Offcanvas.Title>
          </Offcanvas.Header>
        )}
        <Offcanvas.Body className="p-0 d-flex align-items-start position-relative overflow-visible ">
          <button
            type="button"
            className={styles["expand-close"]}
            onClick={documentCloseHandler}
          >
            <CollpaseSvg />
          </button>
          <div className={styles["sidebar-wrapper"]}>
            <div className={styles["sidebar-content"]}>
              {activeTab != "activitylog" &&
                navData !== null &&
                Array.isArray(navData) &&
                navData.map((nav, _index) => (
                  <div
                    key={_index + Math.random()}
                    className={_index !== 0 ? "mt-5": ""}
                  >
                    <h6
                      id={nav["tabName"]}
                      style={{
                        fontSize: "18px",
                        fontWeight: 500,
                      }}
                    >
                      <b>{nav["label"] ?? ""}</b>
                    </h6>
                    <div>{getAllDetailAnalysisSideData(nav)}</div>
                    {nav["services"] &&
                      nav["services"].length > 0 &&
                      nav["services"].map((service, key) => {
                        return (
                          <div key={key + Math.random()}>
                            <strong
                              dangerouslySetInnerHTML={{
                                __html: transformString(
                                  service.name ?? "",
                                  nav["label"],
                                  false
                                ),
                              }}
                            />
                            <p
                              dangerouslySetInnerHTML={{
                                __html: transformString(
                                  service.description ?? "",
                                  nav["label"],
                                  []
                                ),
                              }}
                            />
                          </div>
                        );
                      })}
                    {nav["dataflow"] && nav["dataflow"].length > 0 && (
                      <div className={styles["markup-navigation-wrapper"]}>
                        {nav["dataflow"].map((_flow, key) => {
                          let _k = _flow.name
                            .trim()
                            .replace(/\s+/g, "-")
                            .toLowerCase();
                          const handleButtonClick = (key, path) => {
                            if (activeButton === key) {
                              // If the active button is clicked again, untoggle it
                              setHightlightEdgesContext({
                                key: _k,
                                nodes: path,
                              });
                              setActiveButton(null);
                            } else {
                              // Toggle the new button and untoggle any previously active button
                              setActiveButton(key);
                              // Continue with the current action
                              setHightlightEdgesContext({
                                key: _k,
                                nodes: path,
                              });
                            }
                          };
                          return (
                            <div
                              key={key + Math.random()}
                              className={"canvas_highlight_wrapper"}
                            >
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="button-tooltip">{_flow.description}</Tooltip>}
                              >
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleButtonClick(key, _flow.path)
                                  }
                                  style={
                                    activeButton === key
                                      ? activeButtonStyle
                                      : regularButtonStyle
                                  }
                                >
                                  {_flow.name}
                                </button>
                              </OverlayTrigger>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                ))}
              {activeTab === "activitylog" && (
                <LeftSideActiveLog activitylog={activityLogData} />
              )}
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default LeftSidebar;

function scrollIntoViewIfVisible(target) {
  target.scrollIntoView({ behavior: "smooth" });
}
