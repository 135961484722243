import React, { useState } from 'react';
import jigsawml_logo from "../../../../../images/Jigsaw_logo.svg";
import './Navbar.css';
import { Link } from 'react-router-dom';
import CloseIcon from "../../../../../images/clear.svg";
import HamburgerIcon from "../../../../../images/menu.svg";

const Navbar = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    }

    const isDemoPage = window.location.href.split('/')[window.location.href.split('/').length-1] === "demo-page"

    return (
        <nav className="jigsaw_navbar">
            <div className="jigsaw_navbar_container">
                {/* Logo */}
                <div className="jigsaw_logo_container">
                    <img src={jigsawml_logo} alt="JigsawML Logo" className="jigsaw_logo" />
                </div>

                {/* Hamburger Menu for Mobile */}
                <div className="jigsaw_mobile_menu_icon" onClick={toggleMobileMenu}>
                    {isMobileMenuOpen ? <img src={CloseIcon} alt='CloseIcon' style={{ width: '30px' }} /> : <img src={HamburgerIcon} alt='HamburgerIcon'
                        style={{
                            filter: 'invert(100%) sepia(0%) saturate(7500%) hue-rotate(180deg) brightness(95%) contrast(101%)'
                        }} />}
                </div>

                {/* Nav items in middle - Desktop */}
                <div className="jigsaw_nav_items jigsaw_desktop_nav">
                    <Link
                        to={"/product"}
                        className="jigsaw_nav_item jigsaw_scanner"
                    >
                        <span>Software Scanner</span>
                        <span className="jigsaw_beta_tag">Beta</span>
                    </Link>
                    <Link
                        to={"/blogs"}
                        className="jigsaw_nav_item"
                    >
                        Blogs
                    </Link>
                    <Link
                        to={"/in-press"}
                        className="jigsaw_nav_item"
                    >
                        In the press
                    </Link>
                </div>

                {/* Buttons in separate container - Desktop */}
                <div className="jigsaw_button_container jigsaw_desktop_buttons">
                    <Link
                        to={"/cloud-scanner"}
                        className="jigsaw_demo_button"
                        style={{visibility: isDemoPage && 'hidden'}}
                    >
                        See Demo
                    </Link>
                    <Link
                        to={"/login"}
                        className="jigsaw_login_button"
                    >
                        Login
                    </Link>
                </div>

                {/* Mobile Menu Overlay */}
                {isMobileMenuOpen && (
                    <div className="jigsaw_mobile_menu_overlay">
                        <div className="jigsaw_mobile_nav_items">
                            <Link
                                to={"/product"}
                                className="jigsaw_nav_item jigsaw_scanner"
                            >
                                <span>Software Scanner</span>
                                <span className="jigsaw_beta_tag">Beta</span>
                            </Link>
                            <Link
                                to={"/blogs"}
                                className="jigsaw_nav_item"
                            >
                                Blogs
                            </Link>
                            <Link
                                to={"/in-press"}
                                className="jigsaw_nav_item"
                            >
                                In the press
                            </Link>
                            <div className="jigsaw_mobile_buttons">
                                <Link
                                    to={"/cloud-scanner"}
                                    className="jigsaw_demo_button"
                                >
                                    See Demo
                                </Link>
                                <Link
                                    to={"/login"}
                                    className="jigsaw_login_button"
                                >
                                    Login
                                </Link>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </nav>
    );
};

export default Navbar;