import { Link } from "react-router-dom";
import { useEffect } from "react";
import styles from "./footer.module.css";
import {
  NewLogoSvg,
} from "../../images/svg";

const Footer = () => {
  const isDisabled = false; // To be set to false to enable cloud-scanner

  // Scroll to top when the component is mounted or the route changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Empty dependency array means it runs on mount

  return (
    <div className={styles["footer-container"]}>
      <div className={`container-fluid ${styles["footer-section"]}`}>
        <div className={styles["footer-column"]}>
          <NewLogoSvg />
          <p className={styles["footer-text"]}>
            Your AI-powered<br /> Cloud Intelligence Platform
          </p>
          <p className={styles["footer-copyright"]}>&#169; JigsawML 2025</p>
        </div>
        <div className={`${styles["footer-column"]} ${styles["footer-links"]}`}>
          <p className={styles["footer-heading"]}>Useful links</p>
          <Link
            to="/product"
            className={styles["footer-link"]}
            style={{ cursor: 'pointer' }} // Default cursor
          >
            SW Architecture Visualizer
            <span className={styles["beta-badge"]}>Beta</span>
          </Link>
          <Link
            to={isDisabled ? "#" : "/cloud-scanner"}
            className={`${styles["footer-link"]} ${isDisabled ? styles["disabled"] : ""}`}
            style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }} // Default cursor
            onClick={(e) => isDisabled && e.preventDefault()} // Prevent click if disabled
            >
            Cloud Scanner
            <span className={styles["beta-badge"]}>Free Trial</span>
          </Link>
          <Link
            to={"/blogs"}
            className={styles["footer-link"]}
            style={{ cursor: 'pointer' }}
          >
            Blogs
          </Link>
          <Link
            to="/in-press"
            className={styles["footer-link"]}
            style={{ cursor: 'pointer' }}
          >
            In the press
          </Link>
        </div>
        <div className={styles["footer-column"]}>
          <p className={styles["footer-heading"]}>Write to us</p>
          <a href="mailto:info@jigsawml.com" className={styles["footer-email"]}>info@jigsawml.com</a>
        </div>
      </div>
      <br/>
      <br/>
    </div>
  );
};

export default Footer;
