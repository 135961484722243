import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import './signUpDemo.css';
import midLine from '../../../../images/midLine.svg';
import numberOne from '../../../../images/numberOne.svg';
import numberTwo from '../../../../images/numberTwo.svg';
import signUpVector from '../../../../images/signUpVector.svg';
import openEye from '../../../../images/openEye.svg';
import closedEye from '../../../../images/closedEye.svg';
import linkSentCheck from '../../../../images/linkSentCheck.svg';
import doneTick from '../../../../images/doneTick.svg'

const SignUpDemo = (props) => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [nextDone, setNextDone] = useState(false);
    const [signUpDone, setSignUpDone] = useState(false);

    const handleChangeEmail = (e) => {
        setEmail(e.target.value);
        const regEx = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!regEx.test(e.target.value)) {
            setError(true);
        }
        else {
            setError(false);
        }
    }
    const handleChangePassword = (e) => {
        setPassword(e.target.value);
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body style={{ background: "#2E0249", borderRadius: '24px' }}>
                <div className='signUpDemoContainer'>
                    <div className='confirmPaths'>
                        <div>
                            {
                                !nextDone ?
                                    <img src={numberOne} alt='numberOne' /> :
                                    <img src={doneTick} alt='doneTick' />
                            }
                        </div>
                        <div><img src={midLine} alt='midLine' /></div>
                        <div>
                            {
                                !signUpDone ?
                                    <img src={numberTwo} alt='numberTwo' style={{ opacity: !nextDone && '50%' }} /> :
                                    <img src={doneTick} alt='doneTick' />
                            }
                        </div>
                    </div>
                    <div>
                        {!signUpDone ?
                            (<img src={signUpVector} alt='signUpVector' className='signUpVector' />) :
                            (<img src={linkSentCheck} alt='linkSentCheck' className='linkSentCheck' />)
                        }
                    </div>
                    {
                        !signUpDone ?
                            <div>
                                <div className='signUpLabel'><div>Sign up</div><div>for a free demo</div></div>
                                <div className='createSaaSLabel'>Create your account to get access to our SaaS demo</div>
                            </div> :
                            <div>
                                <div className='signUpLabel'><div>Sign up</div><div>Successfull</div></div>
                                <div className='sentEmail'>We have sent you an confirmation email. Please click on the link in the email to verify.</div>
                            </div>
                    }
                    {
                        !nextDone &&
                        <div className='formContainerDiv'>
                            <form className='formContainer'>
                                <div className='setPasswordLabel'>Enter your email</div>
                                <div><input value={email} onChange={handleChangeEmail} className='inputPassword' placeholder='Enter your email' /></div>
                                {error && (<span style={{ color: 'red', paddingTop: '0px' }}>Enter valid email</span>)}
                                <div><button className='signUpNextButton' onClick={() => setNextDone(true)}>Next</button></div>
                            </form>
                        </div>
                    }
                    {
                        nextDone && !signUpDone &&
                        <div>
                            <form className='formContainer'>
                                <div className='setPasswordLabel'>Set your password</div>
                                <div style={{ position: 'relative' }}>
                                    <input value={password} type={!showPassword ? "text" : "password"} onChange={handleChangePassword} className='inputPassword' placeholder='Type your password' />
                                    <span style={{ position: 'absolute', right: '0', top: '0' }} onClick={() => { setShowPassword(!showPassword) }}>{showPassword ? <img src={openEye} alt='openEye' /> : <img src={closedEye} alt='closedEye' />}</span>
                                </div>
                                {error && (<span style={{ color: 'red', paddingTop: '0px' }}>Enter valid email</span>)}
                                <div><button className='signUpNextButton' onClick={() => setSignUpDone(true)}>Sign Up</button></div>
                            </form>
                        </div>
                    }

                </div>
            </Modal.Body>
        </Modal>
    );
}

export default SignUpDemo;
