import { Link } from "react-router-dom";
import { useEffect } from "react";
import styles from "./Footer.module.css";
import jigsawml_logo from "../../../../../images/Jigsaw_logo.svg";

const Footer = () => {
  // Scroll to top when the component is mounted or the route changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Empty dependency array means it runs on mount

  return (
    <div className={styles["footer-container"]}>
      <div className={`container-fluid ${styles["footer-section"]}`}>
        <div className={styles["footer-column"]}>
          <img src={jigsawml_logo} alt="jigsawml_logo" />
          <p className={styles["footer-text"]}>
            Your AI-powered<br /> Cloud Intelligence Platform
          </p>
          <p className={styles["footer-copyright"]}>&#169; JigsawML 2025</p>
        </div>
        <div style={{display:'flex', gap:'35px'}} className={styles["links-container"]}>
          <div className={`${styles["footer-column"]} ${styles["footer-links"]}`}>
            <p className={styles["footer-heading"]} style={{color:'#FFFFFF'}}>Useful links</p>
            <Link
              to={"/product"}
              className={styles["footer-link"]}
              style={{ cursor: 'pointer' }}
            >
              SW Architecture Visualizer
              <span className={styles["beta-badge"]}>Beta</span>
            </Link>
            <Link
              to={"/blogs"}
              className={styles["footer-link"]}
              style={{ cursor: 'pointer' }}
            >
              Blogs
            </Link>
            <Link
              to="/in-press"
              className={styles["footer-link"]}
              style={{ cursor: 'pointer' }}
            >
              In the press
            </Link>
          </div>
          <div className={styles["footer-column"]}>
            <p className={styles["footer-heading"]} style={{color:'#FFFFFF'}}>Write to us</p>
            <a href="mailto:info@jigsawml.com" className={styles["footer-email"]}>info@jigsawml.com</a>
          </div>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
};

export default Footer;
