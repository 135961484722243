/**
 * SearchModal component for displaying search results and executing commands.
 */
import CommandsMgr from "../canvas_commands";
import { CloudProvider } from "../canvas_commands";
import awsNodeTypes from "../../icons/aws-icons";
import React, { useState, useEffect, useRef } from "react";
import styles from "../view-edit-architecture.module.css";
import { CrossSvg } from "../../../../images/svg/icons";
import Copy from "../../../../images/copy_response.svg";
import copy_all from "../../../../images/copy_all.svg";
import Retry from "../../../../images/retry_img.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useContext } from 'react';
import context from "../../../../store/create-context";

const SearchModal = ({
  items,
  setItems,
  title = "",
  executeCommand,
  setSearchLoader,
  descriptionId,
  setSResponse,
  sResponse,
  socket,
  searchTextRef,
  askAIbuttonClickHandler,
  loadingTime
}) => {
  const [hoveredIndex, setHoveredIndex] = useState(0);
  const [initialHeight, setInitialHeight] = useState(0);
  const [startY, setStartY] = useState(0);
  const isDevEnvironment = process.env.REACT_APP_ENVIRONMENT === "dev";
  let commandsMgr = new CommandsMgr(
    CloudProvider.AWS,
    descriptionId,
    setSResponse,
    sResponse,
    socket
  );
  const { setQueryStr, setMatchedServices, activeSection, setButtonClicked } = useContext(context);
  const askAiResponseRef = useRef(null);

  useEffect(() => {
    // Define the function for handling keydown events
    const handleKeyDown = (event) => {
      if (event.key === "ArrowUp") {
        setHoveredIndex((prevState) => (prevState > 0 ? prevState - 1 : 0));
      } else if (event.key === "ArrowDown") {
        setHoveredIndex((prevState) =>
          prevState < items.length - 1 ? prevState + 1 : items.length - 1
        );
      } else if (event.key === "Enter") { // This most likely isn't working as of Nov 21, 2024.
        event.preventDefault();
        if (searchTextRef.current && searchTextRef.current.value !== "") {
          askAIbuttonClickHandler();
        }
      }
    };

    // Add the event listener to the window
    window.addEventListener("keydown", handleKeyDown);

    // Cleanup: remove the event listener
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const executeCommands = (commandsStr) => {
    // Split the commands string into an array of commands
    const commands = commandsStr.split("\n");
    isDevEnvironment && console.log(commands);
    let newNodes = [],
      newNode = null;
    let newEdges = [],
      newEdge = null;
    // Loop over the commands and call executeCommand for each of them
    for (const command of commands) {
      [newNode, newEdge] = commandsMgr.execute(command, setSearchLoader, false);
      // if newNode is not null and newNode is not in awsNodeTypes
      if (newNode && !awsNodeTypes[newNode.type]) {
        isDevEnvironment && console.log("newNode is not null and newNode is not in awsNodeTypes");
        isDevEnvironment && console.log(newNode);
        continue;
      }
      if (newNode) {
        newNodes.push(newNode);
      }
      if (newEdge) {
        newEdges.push(newEdge);
      }
    }
    // Add the new nodes and edges to the graph
    commandsMgr.updateCanvas(newNodes, newEdges);
    setItems([]);
  };

  const handleCopyAskAIResponse = async (text) =>{
    try {
      await navigator.clipboard.writeText(text.summary + ": " + text.description);
    } catch (err) {
      console.error("Failed to copy", err);
    }
  };

  const handleCopyAllAskAIResponse = async (items) =>{
    try {
      // Create a newline separated string of all the texts
      const copiedText = items.map(item => `${item.summary}:${item.description}`).join("\n");
      await navigator.clipboard.writeText(copiedText);
    } catch (err) {
      console.error("Failed to copy", err);
    }
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    if (askAiResponseRef.current) {
      setInitialHeight(askAiResponseRef.current.getBoundingClientRect().height);
      setStartY(e.clientY);
   }
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e) => {
    if (askAiResponseRef.current) {
      const deltaY = startY - e.clientY;
      const newHeight = initialHeight + deltaY;
      askAiResponseRef.current.style.height = `${newHeight}px`;
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  const handleClick = async (e, text) =>{
    e.preventDefault()
    try {
      setTimeout(() => {
        if (searchTextRef.current) {
          searchTextRef.current.focus();
          searchTextRef.current.setSelectionRange(0, 0);
        }
      }, 0);
      setQueryStr("Give more info on- " + text.summary + ": " + text.description);
      setButtonClicked(true);
    } catch (err) {
      console.error("Failed to copy", err);
    }
  };

  const handleSelectedWord = (e) => {
    e.stopPropagation();
    if (activeSection === "infra_view") {
      const selectedText = window.getSelection().toString();
      setMatchedServices(selectedText);
    }
  }

  const handleDoubleClick = (e ,word) => {
    e.preventDefault();
    if (activeSection === "infra_view") {
      setMatchedServices(word);
    }
  }

  return (
    <>
    <div className={styles['resize-handle']} onMouseDown={handleMouseDown}>
      <div
        className={styles['close-button']}
      >
        <div className={styles['search-number-items']}><span style={{fontWeight:'500'}}>{items.length}</span> rows</div>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="button-tooltip">Copy All</Tooltip>}
        >
          <div className={styles['response-copy']} onClick={() => handleCopyAllAskAIResponse(items)}>
            <img src = {copy_all} alt="copy_all"/>
          </div>
        </OverlayTrigger>
        <span>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="button-tooltip">Retry</Tooltip>}
          >
            <button onClick={askAIbuttonClickHandler}>
              <img src={Retry} alt="retry_img" style={{width:'18px', filter:'invert(0.4)'}}/>
            </button>
          </OverlayTrigger>
        </span>
        <div
          onClick={() => {
            setQueryStr("")
            setItems([])
          }}
          style={{filter:'invert(0.5)'}}
        >
          <CrossSvg/>
        </div>
      </div>
    </div>
    <div className={styles["search-ask-ai-res-modal"]}>
      {items.length > 0 && (
        <div ref={askAiResponseRef} className={styles["resize-ask-ai-res-modal"]}
          draggable="true"
          onDragStart={(e) => {
            e.stopPropagation()
            e.preventDefault()
          }}
        >
          <div
            className={` ${styles['search-modal-class-content']} search-modal`}
          >
            {items?.map((item, index) => (
              <div
                className="d-flex flex-row"
                style={{
                  marginBottom: "1.0rem",
                  borderBottom: "1px solid #3f3f3f"
                }}
                key={index}
                // onClick={() => executeCommands(item.commands)}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <OverlayTrigger
                  placement="auto"
                  container={document.querySelector('.search-modal')}
                  overlay={<Tooltip id="button-tooltip">Right click to get more info</Tooltip>}>
                  <div className={styles['search-modal-item']}
                   style={{opacity: index === hoveredIndex ? 1 : 0.5, width:'330px'}}
                   onContextMenu={(e) => handleClick(e, item)}>
                    <span>
                      {item.summary && (
                        <>
                          {capitalizeWords(item.summary).split(' ').map((word, idx) => (
                            <span key={idx}
                             onMouseUp={(e) => handleSelectedWord(e)}
                             onDoubleClick={(e) => handleDoubleClick(e, word)}
                            >
                              {word}&nbsp;
                            </span>
                          ))}
                          :
                        </>
                      )}
                    </span>
                    <span style={{wordWrap: 'break-word'}}>
                      {item.description.split(' ').map((word, idx) => (
                        <span key={idx}
                         onMouseUp={(e) => handleSelectedWord(e)}
                         onDoubleClick={(e) => handleDoubleClick(e, word)}
                        >
                          &nbsp;{word}
                        </span>
                      ))}
                    </span>
                  </div>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  container={document.querySelector('.search-modal')}
                  overlay={<Tooltip id="button-tooltip">Copy</Tooltip>}>
                  <div className={styles['response-copy']} onClick={() => handleCopyAskAIResponse(item)}>
                    <img src = {Copy} alt="copy_response"/>
                  </div>
                </OverlayTrigger>
              </div>
            ))}
            <div className={styles["message-container"]}>
              <div className={styles["warning-message"]}>
                This response is generated using AI and may contain inaccuracies. 
                Please verify independently & consult a human expert for critical decisions.
              </div>
              <span className={styles["process-time"]}>Query processed in {loadingTime.toFixed(1)} seconds</span>
            </div>
          </div>
        </div>
      )}
    </div>
    </>
  );
};

const capitalizeWords = (str) => {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export default SearchModal;
