import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import localLinkLogo from '../../../../images/localLinkLogo.svg';
import './localInstallerModal.css'
import linkSentCheck from '../../../../images/linkSentCheck.svg'

const LocalInstallerModal = (props) => {
  const [sentLink, setSentLink] = useState(false);
  const [email, setEmail] = useState("");
  const [invalidEmail, setInvalidEmail] = useState(false);

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
    const regEx = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!regEx.test(e.target.value)) {
      setInvalidEmail(true);
    }
    else {
      setInvalidEmail(false);
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body dialogClassName="modal-local-installer" style={{ background: "#2E0249", borderRadius: '24px' }}>
        {
          !sentLink &&
          <div className='localInstallerBody'>
            <div className='localInstallerDetails'>
              <div><img src={localLinkLogo} alt='localLinkLogo'/></div>
              <div className='getFreeLabel'>Get Free Local Installer</div>
              <div className='shareEmailLabel'>Share your email to download the local installer</div>
            </div>
            <div className='localInstallerForm'>
              <form>
                <div className='enterLabel'>Enter your email</div>
                <div><input value={email} className='inputEmail' placeholder='Enter your email' onChange={handleChangeEmail} /></div>
                {invalidEmail && (<span style={{ color: 'red', paddingTop: '20px' }}>Enter valid email</span>)}
                <div className='downloadBtnContainer'><button className='downloadButton' onClick={() => setSentLink(true)}>Get Download Link</button></div>
              </form>
            </div>
          </div>
        }
        {sentLink &&
          <div className='sentLinkContainer'>
            <div><img src={linkSentCheck} alt='linkSentCheck' /></div>
            <div className='linkSentLabel1'>Download Link Sent</div>
            <div className='linkSentLabel2'>We've sent the download link to your email</div>
          </div>
        }
      </Modal.Body>
    </Modal>
  );
}

export default LocalInstallerModal
