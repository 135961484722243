import React, { useEffect, useContext, useState } from "react";
import { useParams } from 'react-router-dom';
import ViewEditArchitecture from "./view-edit-architecture";
import WebSocketInstance from "../../../common/WebSocketInstance";
import { useLocation, useNavigate } from "react-router-dom";
import context from '../../../store/create-context';
import FrameMobileView from "./FrameMobileView";

function NewSafeViewEditArchitecture({
  sResponse,
  qResponse,
  setSResponse,
  setQResponse,
  descriptionId,
  socket,
  loadingArch,
  setLoadingArch,
  logoutHandler
}) {
  const isDevEnvironment = process.env.REACT_APP_ENVIRONMENT === "dev";
  isDevEnvironment && console.log(
    "Starting function NewSafeViewEditArchitecture; sResponse:",
    sResponse
  );
  const [show, setShow] = useState(false);
  const[isMobile, setIsMobile] = useState(window.innerWidth <= 425)

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 425;
      setIsMobile(isMobile);
      if (!isMobile) {
        setShow(false);
      }
    };
    if (isMobile) {
      setShow(true);
    }else{
      setShow(false);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);


  //Intialize socket
  if (!socket) {
    socket = WebSocketInstance.getInstance();
  }
  //get projectId from project list
  const location = useLocation();
  const navigate = useNavigate();
  const { projectId, projectName, newProject, sandboxMode, projectDescription } =
    location.state || {}; // Default to {} if state is undefined

  const ctx = useContext(context);
  const { pathProjectId } = useParams();
  var finalProjectId = projectId;
  // Check if the pathProjectId and projectId are not equal
  if (pathProjectId !== projectId) {
    // Handle the error - you can also set an error message in your state
    isDevEnvironment && console.log("project_id is ", projectId);
    isDevEnvironment && console.log("pathProjectId is ", pathProjectId);
    finalProjectId = pathProjectId;

    // pathProjectId is the project id in the URL. it will take precedence over projectId read from session storage
    sessionStorage.setItem("project_id", pathProjectId);
  }
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    var useCache = queryParams.get('useCache');
    ctx.setUseCache(useCache);
  }, []);
  const handleBack = (e) => {
    e.preventDefault();
    let ele = document.querySelector(".adjacent-component");
    if (ele) {
      ele.innerHTML = "";
    }
    setSResponse((prev) => {
      return [];
    });
    sessionStorage.removeItem("project_id");
    window.history.replaceState(null, null, "/dashboard");
    navigate("/dashboard", { replace: true });
  };

  useEffect(() => {
    window.addEventListener("popstate", handleBack);

    return () => {
      window.removeEventListener("popstate", handleBack);
    };
  }, []);

  return (
    <>
      { !isMobile ? <ViewEditArchitecture
        sResponse={sResponse}
        qResponse={qResponse}
        setSResponse={setSResponse}
        setQResponse={setQResponse}
        descriptionId={descriptionId}
        socket={socket}
        loadingArch={loadingArch}
        setLoadingArch={setLoadingArch}
        projectId={finalProjectId}
        projectDescription={projectDescription}
        projectName={projectName}
        logoutHandler={logoutHandler}
        sandboxMode={sandboxMode}
        newProject={newProject}
      />
      :
      <FrameMobileView
        show ={show}
        setShow={setShow}
      />
      }
    </>
  );
}

export default NewSafeViewEditArchitecture;
