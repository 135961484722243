import React, { useEffect, useState, useContext } from "react";
import styles from "./view-edit-architecture.module.css";
import WebSocketInstance from "../../../common/WebSocketInstance";
import {
  DownloadSvg,
  DocSvg,
  NotionIconSvg,
  TerraformIconSvg,
  PulumiIconSvg,
} from "../../../images/svg/icons";
import { Dropdown } from "react-bootstrap";
import context from "../../../store/create-context";
import lucidchart from "../../../images/lucidchart.svg";
import DownloadButton from "../new-canvas/component/DownloadButton";

const useToggle = (initialState = false) => {
  const [state, setState] = useState(initialState);
  const toggle = () => setState(!state);
  return [state, toggle];
};

const DownloadButtonDropdown = ({
  setCheckActivity,
  projectName,
  disabled
}) => {
  const { setShowToastr, openSpinnerHandler, closeSpinnerHandler, activeSection } = useContext(context);
  const [isPlusDropdownOpen, togglePlusDropdown] = useToggle();

  const getTerraform = () => {
    openSpinnerHandler();
    const socket = WebSocketInstance.getInstance();
    socket.send("retrieve_terraform", {
      project_id: sessionStorage.getItem("project_id"),
    });
    setCheckActivity(true);
  };

  const downloadPDF = () => {
    openSpinnerHandler();
    const socket = WebSocketInstance.getInstance();
    socket.send("retrieve_pdf", {
      project_id: sessionStorage.getItem("project_id"),
    });
    setCheckActivity(true);
  };

  useEffect(() => {
    const socket = WebSocketInstance.getInstance();
    socket.addEventListener("retrieve_pdf", pdfReceipt);
    socket.addEventListener("retrieve_terraform", terraformReceipt);
    setCheckActivity(true); // Set checkActivity to true when the component initially loads

    return () => {
      socket.removeEventListener("retrieve_pdf", pdfReceipt);
      socket.removeEventListener("retrieve_terraform", terraformReceipt);
    };
  }, []);

  const pdfReceipt = (event) => {
    if (event?.success === false) {
      setShowToastr({
        show: true,
        type: "error",
        text: event?.error,
      });
    } else {
      const blob = new Blob([event.pdf_data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = projectName + ".pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    closeSpinnerHandler();
  };

  const terraformReceipt = (event) => {
    if (event?.success === false) {
      setShowToastr({
        show: true,
        type: "error",
        text: event?.error,
      });
    } else {
      // console.log("terraformReceipt", event);
      // Convert the event object to a string format. Adjust the serialization as needed.
      const content = JSON.stringify(event["terraform_code"], null, 2);

      // Create a blob of the content
      const blob = new Blob([content], { type: "text/plain" });

      // Create a link element
      const link = document.createElement("a");

      // Assign the blob URL to the link's href
      link.href = URL.createObjectURL(blob);

      // Set the download attribute with a filename
      link.download = projectName + ".tf";

      // Append the link to the document, trigger a click to start the download, and then remove the link
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    closeSpinnerHandler();
  };

  return (
    <>
      <Dropdown
        className={`${styles["navigation-item-download"]
        } ${styles["patch-dropdown"]} ${styles["download-btn"]} ${isPlusDropdownOpen && styles["download-btn-active"]}`}
        show={isPlusDropdownOpen}
        onToggle={() => togglePlusDropdown()}
        data-testid="plus-dropdown"
      >
        <Dropdown.Toggle>
          <DownloadSvg />
          <span style={{paddingLeft:'6px'}}>Download</span>
        </Dropdown.Toggle>
        <Dropdown.Menu className={`${styles["plus-dropdowm-menu"]}`}>
          <Dropdown.Item
            className={styles["plus-dropdowm-item"]}
            href="#"
            data-testid="file-upload"
            disabled
            style={{ opacity: 0.3}}
          >
            <img src={lucidchart} alt="lucidchart" />
            <span>Lucidchart</span>
          </Dropdown.Item>

          <Dropdown.Item
            disabled
            style={{ opacity: 0.3 }}
            className={styles["plus-dropdowm-item"]}
            href="#"
            onClick={getTerraform}
            data-testid="file-upload"
          >
            <NotionIconSvg />
            <span>Notion (LiveSync)</span>
          </Dropdown.Item>
          <Dropdown.Item
            className={styles["plus-dropdowm-item"]}
            href="#"
            data-testid="file-upload"
            disabled={disabled || activeSection==="infra_view"}
            style={{opacity:(disabled || activeSection==="infra_view") && 0.3 }}
          >
            <span><DownloadButton projectName={projectName}/></span>
          </Dropdown.Item>
          <Dropdown.Item
            className={styles["plus-dropdowm-item"]}
            href="#"
            onClick={downloadPDF}
            data-testid="file-upload"
            disabled={disabled}
            style={disabled ? { opacity: 0.3 } : {}}
          >
            <DocSvg />
            <span>Cloud Infra Summary</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default DownloadButtonDropdown;
