import React from "react";
import { getBezierPath, BaseEdge, EdgeLabelRenderer } from "reactflow";
import { useContext } from "react";
import context from "../../../store/create-context";
import "./canvas.component.css";
import { useState } from "react";

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  markerEnd,
}) {
  const [isHovered, setIsHovered] = useState(false);
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const ctx = useContext(context);

  const edgeStyle = {
    ...style,
    stroke: isHovered ? "#00B2D9" : data.color || ctx.edgeColor,
    // stroke: data.color, // Set the stroke color to the specified color
    strokeWidth: isHovered ? "1.5" : data.strokeWidth,
    strokeDasharray: 10
  };  

  const customTextStyle = {
    fill: "#ffffff", // set the fill color of the text to red
    fontFamily: "Arial", // set the font family to Arial
    fontSize: 12, // set the font size to 12 pixels
    fontWeight: "bold", // set the font weight to bold
    transform: "rotate(-185deg)", // rotate the text by -45 degrees
    transformOrigin: "50% 50%", // set the transform origin to the center of the text
  };
const handleMouseEnter = () => {
    setIsHovered(true);
}
const handleMouseLeave = () => {
  setIsHovered(false);
}
  const [rename, setRename] = useState(false)
  const [renamedLabel, setRenamedLabel] = useState(data.text);
  const [tempLabel, setTempLabel] = useState(data.text);

  const handleChange = (event) => {
    setTempLabel(event.target.value);
  };

  const handleBlur = () => {
    setRenamedLabel(tempLabel);
    setRename(false);
  };

  return (
    <>
      <BaseEdge
        id={id}
        path={edgePath}
        style={edgeStyle}
        markerEnd={markerEnd}
        //  label={data.text}
        //  labelX={labelX}
        //  labelY={labelY}
        //  labelShowBg
        //  labelBgPadding={[10,10]}
        //  labelBgBorderRadius={8}
        //  labelStyle={{ fill: '#fff' }}
        //  labelBgStyle={{ fill: '#181818' }}
      />
      <div 
       onMouseEnter={handleMouseEnter}
       onMouseLeave={handleMouseLeave}
      >
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            background: "#181818",
            padding: "0.4rem 0.8rem",
            borderRadius: 4,
            fontSize: 12,
            fontWeight: 400,
            border: "1px solid #202020",
            color: "#fff",
            width: "12%",
            maxWidth: "fit-content",
            pointerEvents:"all"
          }}
          className="nodrag nopan"
        >
          {rename ? (
            <span>
              <input
                type="text"
                value={tempLabel}
                onChange={handleChange}
                onBlur={handleBlur}
                autoFocus
                style={{ width: "110px", background:'#0c0c0c', color:'#FFFFFF', borderRadius:'4px'}}/>
            </span>
          ) : (
            <span onClick={() => setRename(true)}>{renamedLabel}</span>
          )}
        </div>
      </EdgeLabelRenderer>
      </div>
      {/* <path
                    id={id}
                    // style={style}
                    className="react-flow__edge-path"
                    d={edgePath}
                    markerEnd={markerEnd}
                />  */}
      {/*<text>
                <textPath href={`#${id}`} style={customTextStyle} startOffset="50%" textAnchor="middle">
                    {data.text}
                </textPath>
            </text> */}
    </>
  );
}