import React, { useState } from 'react';
import Navbar from '../newProductPage/components/Navbar';
import demoFrame from '../../../../images/demoFrame.svg';
import './demoPage.css';
import light_curve from "../../../../images/Light curve.svg";
import ReachOutToUs from './ReachOutToUs';
import LocalInstallerModal from './LocalInstallerModal';
import SignUpDemo from './SignUpDemo';
import Footer from '../newProductPage/components/Footer';

const DemoPage = () => {

  const [showDemoModal, setShowDemoModal] = useState(false);
  const [showLocalInstaller, setShowLocalInstaller] = useState(false);
  const [showDemoSignUp, setShowDemoSignUp] = useState(false);

  return (
    <div className="demo-page">
      <div className="navbar-container">
        <Navbar />
      </div>
      <div className="content-container">
        <div style={{ zIndex: '9' }}>
          <div className="top-section">
            <div className="product-tour-label">Product Tour</div>
            <div className="btns-container">
              <div>
                <button className="try-demo-btn" onClick={() => setShowDemoSignUp(true)}>Try Web Demo</button>
              </div>
              <div>
                <button className="local-installer-btn" onClick={() => setShowLocalInstaller(true)}>Local Installer</button>
              </div>
            </div>
          </div>
          <div>
            <img src={demoFrame} alt="demoFrame" className='demoFrame' />
          </div>
          <div>
            <button className="reach-out-btn" onClick={() => setShowDemoModal(true)}>Reach Out To Us</button>
          </div>
        </div>
        <div className='linear-gredient-content-container'></div>
      </div>
      <div className="subscribe-section">
        <span className="subscribe-text">Subscribe to our newsletter</span>
        <div className="email-container">
          <input
            type="email"
            placeholder="Type your email"
            className="email-input"
          />
          <button className="subscribe-button">
            Sign Up
          </button>
        </div>
        <div className="subscribe-container-bg">
        </div>
      </div>
      <div>
        <img src={light_curve} alt="light_curve" style={{ width: '100%', background: '#0B0D15' }} />
        <Footer />
      </div>
      {showDemoModal && <ReachOutToUs show={showDemoModal} onHide={() => setShowDemoModal(false)} />}
      {showLocalInstaller && <LocalInstallerModal show={showLocalInstaller} onHide={() => setShowLocalInstaller(false)} />}
      {showDemoSignUp && <SignUpDemo show={showDemoSignUp} onHide={() => setShowDemoSignUp(false)} />}
    </div>
  );
};

export default DemoPage;
