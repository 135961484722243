import React, { useContext } from 'react';
import { getRectOfNodes, getTransformForBounds } from 'reactflow';
import { toPng } from 'html-to-image';
import CreateContext from '../../../../store/create-context';
import download_image_icon from '../../../../images/download_image_icon.svg';



const imageWidth = 1280;
const imageHeight = 768;

function DownloadButton({projectName}) {
  const {reactFlowInstance} = useContext(CreateContext)

  function downloadImage(dataUrl) {
    const a = document.createElement('a');

    a.setAttribute('download',projectName + '.png');
    a.setAttribute('href', dataUrl);
    a.click();
  }
  const onClick = () => {
    // we calculate a transform for the nodes so that all nodes are visible
    // we then overwrite the transform of the `.react-flow__viewport` element
    // with the style option of the html-to-image library
    const nodesBounds = getRectOfNodes(reactFlowInstance.getNodes());
    const transform = getTransformForBounds(nodesBounds, imageWidth, imageHeight, 0.5, 2);

    toPng(document.querySelector('.react-flow__viewport'), {
      backgroundColor: '#0f0f0f',
      width: imageWidth,
      height: imageHeight,
      style: {
        width: imageWidth,
        height: imageHeight,
        transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
      },
        skipFonts: true,
    }).then(downloadImage);
  };

  return (
    <div onClick={onClick} style={{display:'flex', gap:'10px'}}>
     <span><img src={download_image_icon} alt="download_image_icon" /></span>
      <span>Architecture Diagram</span>
    </div>
  );
}

export default DownloadButton;
