import React, { useState, useEffect, useContext } from "react";
import Toast from 'react-bootstrap/Toast';
import styles from "./FooterBar.module.css";
import { StartSvg} from "../../../images/svg/icons";
import CommandsMgr from "./canvas_commands";
import { CloudProvider } from "./canvas_commands";
import { Form, Nav, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import SearchModal from "./components/search-model.component";
import Collapse from "react-bootstrap/Collapse";
import { CollpaseSvg } from "../../../images/svg/icons";
import expandBtn from "../../../images/expand.svg";
import context from "../../../store/create-context";
import CustomTypeHead from "./CustomTypeHead";
import Button from "react-bootstrap/Button";
import info from "../../../images/info_icon.svg";
import { handleClickOnAskAi } from "../../../utils/google-analytics";
import QuestionsModal from './components/questions-model.component';

const FooterBar = (props) => {

  //context
  const { footerSearchText, footerBtn, setFooterSearchText, setFooterBtn, edgeColor, userId,
    setShowToastr, activeSection, setQueryStr, queryStr, buttonClicked, setButtonClicked, disableAskAi } = useContext(context);
  const ctx = useContext(context);
  const { descriptionId, sResponse, setSResponse, socket } = props;
  const [ position, setPosition ] = useState({ x: 110, y: 0 });
  const [ dragging, setDragging ] = useState(false);
  const handleDragStart = (e) => {
    setDragging(true);
    const setX = e.clientX - position.x;
    const setY = e.clientY - position.y;
    const handleMouseMove = (e) => {
        setPosition({ x: e.clientX - setX, y: e.clientY - setY });
    };
    const handleMouseUp = () => {
        setDragging(false);
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    };
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };


  //states
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [searchLoader, setSearchLoader] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showQuesModal, setShowQuesModal] = useState("");
  const [loadingTime, setLoadingTime] = useState(0);

  //ref
  const searchText = React.createRef();

  let commandsMgr = new CommandsMgr(
    CloudProvider.AWS,
    descriptionId,
    setSResponse,
    sResponse,
    socket
  );

  let startTime;
  const addAskAIListener = () => {
    socket.addEventListener("ask_ai", (event) => {
      // Check if the event has the "architecture" 
      if (event?.success === false) {
        setShowToastr({
          show: true,
          type: "error",
          text: event?.error,
        });
      } else {
        const endTime = Date.now();
        setLoadingTime((endTime - startTime) / 1000);
        let items = event["items"];
        setItems(items);
        setSearchLoader(false);
        setFooterBtn(null);
      }
    });
  };

  const addGetQuesListener = () => {
    socket.addEventListener("get_questions", (event) => {
      if (event?.success === false) {
        setShowToastr({
          show: true,
          type: "error",
          text: event?.error,
        });
      } else {
        setQuestions(event);
        setSearchLoader(false);
        setFooterBtn(null);
      }
    });
  };

  const cancelRequest = () => {
    if(searchLoader){
      socket.socketRef.off("ask_ai")
      setSearchLoader(false);
      setQueryStr("");
    }
  }
  //Effects
  useEffect(() => {
    if (footerSearchText !== "" && footerSearchText !== undefined) {
      if (searchText.current) {
        searchText.current.value = footerSearchText;
        setFooterSearchText("");
      }
    }
  }, [footerSearchText]);

  // Handlers
  let _interval = null;
  const executeCommandFn = (value) => {
    startTime = Date.now();
    setSearchLoader(true);
    clearTimeout(_interval);
    _interval = setTimeout(() => {
      addAskAIListener();
      commandsMgr.execute(value, ctx.activeSection, setSearchLoader, undefined, sResponse);
    }, 1000);
  };

  const keyDownHandler = (event) => {
    event.stopPropagation();
    if (event.key === "/" || event.key === "Enter") {
      event.preventDefault();
      const value = event.target.value;
      if (value !== "") {
        executeCommandFn(searchText.current.value)
      }
    }
  };

  const askAIbuttonClickHandler = () => {
    if (searchText.current) {
      if (searchText.current.value !== ""){
        handleClickOnAskAi(userId, ctx.activeSection);
        executeCommandFn(searchText.current.value); 
      }
      else searchText.current.focus();
    }
  };

  const tabHandler = (tab) => {
    setShowQuesModal(tab)
    setFooterBtn(tab);
    searchText.current.value = "";
    setItems([]);
    let project_id = sessionStorage.getItem('project_id');
    setSearchLoader(true);
    addGetQuesListener()
    // TODO: Only on general tab for now
    socket.send("get_questions", JSON.stringify({
      project_id: project_id,
      active_tab: ctx.activeSection,
      type: tab,
    }));
  };

  const InputField = {
    borderRadius: "0.5rem",
    color: "white",
    width: "24rem",
    padding: " 0.4rem 1rem",
    border: `${activeSection!="infra_view"? "1px solid #3d3d3d": "1px solid rgb(110 108 108)"}`,
    backgroundColor: `${activeSection==="infra_view" && "rgba(39, 39, 39, 1)"}`
  };

  useEffect(() => {
    if (queryStr && buttonClicked) {
      askAIbuttonClickHandler();
      setButtonClicked(false);
    }
  }, [queryStr, buttonClicked]);

  const handleInputFocus = () => {
    setIsFocused(true);
  }

  const handleInputBlur = () => {
    setIsFocused(false);
  }
  const handleValueChange = (e) =>{
    setQueryStr(e.target.value);
  }

  return (
    <div className={styles["main-footer"]}>
      {activeSection !== "infra_view" && <span className={styles["thick-border"]} style={{color: edgeColor}}>
        {/* Thick borders indicates that the node is a subgraph and can be double
        clicked to zoom in further   */}
        Don't forget to double-click on thick border nodes.
      </span>}
      <div className={styles["footer-wrapper"]}
        draggable={!isFocused} onDragStart={handleDragStart}
        style={{top: position.y + 'px', left: position.x + 'px', cursor: dragging ? 'grabbing' : 'grab', pointerEvents: dragging ? "none" : "all"}}>
        <div className={styles["footer-grid"]}>
          {showModal &&
          <Toast onClose={() => setShowModal(false)} bg='dark' className={styles["about-ask-ai-res"]}>
            <Toast.Header className={styles["response-popup"]}>
              <span style={{fontWeight: '600'}}>About Ask AI</span>
            </Toast.Header>
            <Toast.Body style={{color:'rgb(160, 159, 159)'}}>Ask AI simplifies your challenges by providing real-time answers
               to questions about code architecture and cloud infrastructure</Toast.Body>
          </Toast>}
          <Collapse in={open} dimension="width">
            <div id="example-collapse-text">
            <div className={styles["advance-search-input-field"]}>
            {items?.length > 0 && (
              <SearchModal
                items={items}
                setItems={setItems}
                title={""}
                setSearchLoader={setSearchLoader}
                descriptionId={descriptionId}
                setSResponse={setSResponse}
                sResponse={sResponse}
                socket={socket}
                searchTextRef={searchText}
                askAIbuttonClickHandler={askAIbuttonClickHandler}
                loadingTime={loadingTime}
              />
            )}
          </div>
              <div className={`${activeSection==="infra_view" && styles["infra-view-bg"]} ${styles["search-grid"]}`}>
                <div className={styles["history"]} onClick={() => setShowModal(!showModal)}>
                  <img src={info} alt="info_icon" style={{width:'24px', opacity:'0.3'}}/>
                </div>
                <div className={showQuesModal === "general" ? styles["advance-questions-field-general"]
                  : styles["advance-questions-field-cost"]}>
                  {questions?.length > 0 && (
                    <QuestionsModal
                      items={questions}
                      setItems={setQuestions}
                      title={""}
                      setSearchLoader={setSearchLoader}
                      descriptionId={descriptionId}
                      setSResponse={setSResponse}
                      sResponse={sResponse}
                      socket={socket}
                      searchTextRef={searchText}
                    />
                  )}
                </div>
                <div className={styles["input-btn-wrapper"]}>
                  <Form.Group
                    className={`position-relative ${styles["input-search-wrapper"]}}`}
                  >
                    <input
                      style={InputField}
                      ref={searchText}
                      value={queryStr}
                      onChange={handleValueChange}
                      onKeyDown={keyDownHandler}
                      placeholder="Ask any question about your systems here…"
                      onFocus={handleInputFocus}
                      onBlur={handleInputBlur}
                      className={isFocused ? styles["input-focus"]: styles["input-blur"]}
                      disabled={disableAskAi}
                    />

                    {searchLoader && (
                      <Spinner
                        animation="grow"
                        size="sm"
                        style={{
                          position: "absolute",
                          right: "4%",
                          top: "35%",
                          color: "#fff",
                        }}
                      />
                    )}
                  </Form.Group>
                  {footerBtn !== null && (
                    <CustomTypeHead
                      textData={footerBtn}
                      sResponse={sResponse}
                      setFooterBtn={setFooterBtn}
                    />
                  )}
                  <div style={{display:'flex'}}>
                    <span className={styles["ques-text"]}>Sample Questions:</span>
                    <div className={`${styles["all-btn-wrapper"]} ${activeSection === "infra_view" && styles["all-btn-wrapper-infra"]}`}>
                      <Nav.Link
                        href="#"
                        className={`${styles["group-btn"]} ${
                          footerBtn === "general" && styles["active"]
                        }`}
                        onClick={() => {
                          tabHandler("general");
                        }}
                        title="Get a list of generic questions to ask"
                        disabled={disableAskAi}
                      >
                        <span>General</span>
                      </Nav.Link>
                      <Nav.Link
                        href="#"
                        className={`${styles["group-btn"]} ${
                          footerBtn === "cost" && styles["active"]
                        }`}
                        onClick={() => {
                          tabHandler("cost");
                        }}
                        title="Get a list of cost questions to ask."
                        disabled={disableAskAi}
                      >
                        <span>Cost</span>
                      </Nav.Link>
                      {/* <Nav.Link
                        href="#"
                        className={`${styles["group-btn"]} ${
                          footerBtn === "security" && styles["active"]
                        } ${styles["disabled-link"]}`}
                        onClick={() => {
                          tabHandler("security");
                        }}
                        title="Get a list of security questions to ask. Currently disabled."
                        disabled // Remove this attribute to enable the cost question query
                      >
                        <span style={{ fontSize: '0.75em' }}>Security<br/>(Disabled)</span>
                      </Nav.Link> */}
                    </div>
                  </div>
                  {searchLoader === true ? <button
                  className={styles["cancel-btn"]}
                    onClick={cancelRequest}
                  >
                    Cancel
                  </button>:
                  <OverlayTrigger
                    placement="top"
                    overlay={disableAskAi ? <Tooltip id="button-tooltip">Ask AI will be available in a moment</Tooltip> : <></>}
                  >
                    <div>
                      <button
                        className={styles["ai-btn"]}
                        onClick={askAIbuttonClickHandler}
                        disabled={disableAskAi}
                        style={{opacity: disableAskAi && '0.6', cursor:disableAskAi && 'default'}}
                      >
                        <StartSvg />
                        <span>Ask AI</span>
                      </button>
                    </div>
                  </OverlayTrigger>
                  }
                  <button
                    onClick={() => {
                      setOpen(!open)
                      setShowModal(false)
                    }}
                    className={styles["btn-open"]}
                  >
                    {activeSection!== "infra_view" ? <CollpaseSvg /> : <img src={expandBtn} alt="expand"/>}
                  </button>
                </div>
              </div>
            </div>
          </Collapse>
        </div>
        {!open ? (
          <OverlayTrigger
            placement="top"
            overlay={disableAskAi ? <Tooltip id="button-tooltip">Ask AI will be available in a moment</Tooltip> : <></>}
          >
            <div
              className={styles["ask-ai-expand-collapse"]}
            >
              <Button
                style={{
                  height: "40px",
                  width: "100px",
                  position: "absolute",
                  bottom: "0px",
                  right: "1px",
                }}
                className={`${styles["ai-btn"]} ${dragging ? styles["ai-btn-drag"] : ""}`}
                onClick={() => {
                  setOpen(!open)
                }}
                disabled={disableAskAi}
              >
                <StartSvg />
                <span>Ask AI</span>
              </Button>
            </div>
          </OverlayTrigger>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default FooterBar;
