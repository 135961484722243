const { MarkerType } = require("reactflow");
const { digl } = require("@crinkles/digl");
const allNodeTypes = require("../../new-icons/all-icons");
/**
 * Gets the graph from the backend server and
 * converts it into a graph format that React
 * can render.
 *
 * We are going to follow a 3-step process:
 * 1. Create nodes that need to be displayed.
 * 2. Find x,y coordinates
 * 3. Create the required handles and create connections.
 */
export function get_visualization(raw_graph) {
  // Step 1:
  // Create placeholders for all the nodes that would be displayed.
  // After this we just need to fill in the placeholders.
  const final_nodes = create_display_nodes(raw_graph);

  // Step 2:
  // One of the placeholders is x,y coordinates. We will use Digl to do it.
  const edges_digl = to_digl_format(raw_graph);
  var forest = digl(edges_digl, { solitary: [] });

  // Step 3:
  // Figure out which handles are required.

  // Start off with a data structure that will help us
  // to avoid creating the same handles repeatedly.
  const handles_tracker = create_handles_tracker(raw_graph);

  // Let's create the edges through the right handles.
  const final_edges = {};
  const connections = list_reqd_connections(raw_graph);
  for (const source of Object.keys(connections)) {
    for (const tup of connections[source]) {
      let target, ilabel, einfo, description, description_markup;
      var isUpsideDown = false;
      target = tup[0];
      ilabel = tup[1];
      einfo = tup[2];
      description = tup[3] ?? "";
      description_markup = tup[4] ?? "";
      // if source in previous levels then connected to left of target.
      // if source in same level but at the top, connected to top of target.
      const compare_result = is_source_previous_level(source, target, forest);
      var src_dirn, tar_dirn;
      if (compare_result === 1) {
        src_dirn = HandleDirns.Right;
        tar_dirn = HandleDirns.Left;
        // check for the presence of an edge in the opposite direction.
        // if present, then add the label of the other edge to the label of this edge.
        if (check_connection(connections, target, source)) {
          ilabel = ilabel + "/" + get_label(connections, target, source);
        }
      } else if (compare_result === 2) {
        src_dirn = HandleDirns.Bottom;
        tar_dirn = HandleDirns.Top;
      } else if (compare_result === 3) {
        src_dirn = HandleDirns.Top;
        tar_dirn = HandleDirns.Bottom;
      } else if (compare_result === 4) {
        src_dirn = HandleDirns.Left;
        tar_dirn = HandleDirns.Right;
        // check for the presence of an edge in the opposite direction.
        // if present, then ignore this label as it is already added to the other edge.
        if (check_connection(connections, target, source)) {
          ilabel = "";
        } else {
          // the edge is going from right to left, so rotate the label.
          isUpsideDown = true;
        }
      }

      // Creating handles if not already for both source and target.
      const source_handle = create_handle_if_reqd(
        source,
        HandleDestns.Source,
        src_dirn,
        handles_tracker,
        final_nodes
      );
      const target_handle = create_handle_if_reqd(
        target,
        HandleDestns.Target,
        tar_dirn,
        handles_tracker,
        final_nodes
      );
      const edgeId = "e_" + source_handle + "_" + target_handle;

      let checkSourceNodeExist = raw_graph.nodes.find(
        (node) => node.id === source
      );
      let checkTargetNodeExist = raw_graph.nodes.find(
        (_node) => _node.id === target
      );
      if (checkSourceNodeExist && checkTargetNodeExist) {
        final_edges[edgeId] = {
          id: edgeId,
          source: source,
          target: target,
          sourceHandle: source_handle,
          targetHandle: target_handle,
          // type: "custom", 'bezier', 'smoothstep','default
          type: "custom",
          animated: true,
          data: {
            text: ilabel,
            isUpsideDown: isUpsideDown,
            color: "white",
            description: description,
            description_markup: description_markup,
          },
          info: einfo,
          markerEnd: {
            type: MarkerType.Arrow, //ArrowClosed
            width: 40,
            height: 40,
            stroke: "#FFFFFF",
          },
          style: {
            strokeWidth: "0.5px",
            stroke: "#FFFFFF",
            color: "#FFFFFF",
          },
          // label: ilabel,
        };
      }
    }
  }

  // lets create handles for the nodes created manually by user
  for (const node of raw_graph.nodes ?? []) {
    create_handle_if_reqd(
      node.id,
      HandleDestns.Source,
      HandleDirns.Right,
      handles_tracker,
      final_nodes
    );
    create_handle_if_reqd(
      node.id,
      HandleDestns.Target,
      HandleDirns.Left,
      handles_tracker,
      final_nodes
    );
    create_handle_if_reqd(
      node.id,
      HandleDestns.Source,
      HandleDirns.Top,
      handles_tracker,
      final_nodes
    );
    create_handle_if_reqd(
      node.id,
      HandleDestns.Target,
      HandleDirns.Bottom,
      handles_tracker,
      final_nodes
    );
  }

  // log_to_console("In memory nodes after everything", final_nodes);
  // log_to_console("In memory edges after everything", final_edges);
  return {
    nodes: Object.values(final_nodes),
    edges: Object.values(final_edges),
  };
}

let _i = 0;
const color = [
  "#dbc414",
  "#dbc414",
  "#14dbc7",
  "#14dbc7",
  "#db14bd",
  "#db1414",
];

function create_display_nodes(raw_graph) {
  //log_to_console("raw_graph", raw_graph);
  // console.log("raw_graph:"+raw_graph);
  const customNodeStyle = {
    color: "blue", // set the color of the text to red
    borderColor: "#232F3E",
    borderWidth: "1px",
  };

  const final_nodes = {};
  for (const raw_node of raw_graph.nodes ?? []) {
    if (color[_i] !== undefined) {
      customNodeStyle["color"] = color[_i];
      _i++;
    } else {
      _i = 0;
      customNodeStyle["color"] = color[_i];
    }

    if (final_nodes[raw_node.id] === undefined) {
      // check node type start with AWS
      if (raw_node?.type.includes(":")) {
        var nodes_type = raw_node?.type?.split(":")[1]?.toLowerCase().trim();
      } else if (raw_node?.type.startsWith("AWS")) {
        nodes_type = raw_node?.type?.split("AWS")[1]?.toLowerCase().trim();
      } else {
        nodes_type = raw_node?.type?.toLowerCase().trim();
      }
      final_nodes[raw_node.id] = {
        id: raw_node.id,
        type: is_type_known(nodes_type)
          ? nodes_type
          : is_type_known(raw_node?.name?.toLowerCase().trim())
          ? raw_node?.name?.toLowerCase().trim()
          : "cog",
        position: { x: 0, y: 0 },
        data: {
          label: raw_node.data?.label,
          size: 80,
          handles: [],
          style: {
            ...customNodeStyle,
            // borderWidth: raw_node.sub_graph ? "3px" : "1px",
            borderColor: "#4E4E4E",
          },
          has_subgraph: raw_node.sub_graph ? true : false,
          associations: raw_node.data?.associations,
          node_type: raw_node?.type,
        },
        parent: raw_node.parent,
        jml_id:raw_node.jml_id,
        info: raw_node.info,
        description: raw_node.description,
        description_markup: raw_node.description_markup,
        url: raw_node.url,
        name: raw_node.name,
        reason: raw_node.reason,
        rid: raw_node.rid,
        rurl: raw_node.rurl,
      };
    }
  }

  _i = 0;
  // log_to_console("final nodes", final_nodes);
  return final_nodes;
}

class HandleDestns {
  static Source = new HandleDestns("S", "source");
  static Target = new HandleDestns("T", "target");

  constructor(short, long) {
    this.short = short;
    this.long = long;
  }
  toString() {
    return `HandleDestns.${this.short}`;
  }
}

class HandleDirns {
  static Left = new HandleDirns("L", "left");
  static Right = new HandleDirns("R", "right");
  static Top = new HandleDirns("T", "top");
  static Bottom = new HandleDirns("B", "bottom");

  constructor(short, long) {
    this.short = short;
    this.long = long;
  }
  toString() {
    return `HandleDirns.${this.short}`;
  }
}

function to_digl_format(raw_graph) {
  var edges_digl = [];
  if (raw_graph.edges === undefined) {
    return edges_digl;
  }

  for (const raw_edge of raw_graph.edges) {
    var edge = {
      source: raw_edge.source,
      target: raw_edge.target,
    };
    edges_digl.push(edge);
  }
  return edges_digl;
}

function create_handles_tracker(raw_graph) {
  const handles = {};
  for (const raw_node of raw_graph.nodes ?? []) {
    handles[raw_node.id] = {};
    for (const destn of Object.values(HandleDestns)) {
      handles[raw_node.id][destn.short] = {};
      for (const dir of Object.keys(HandleDirns)) {
        handles[raw_node.id][destn.short][dir.short] = false;
      }
    }
  }
  return handles;
}

function list_reqd_connections(raw_graph) {
  var connections = {}; // Keep track of targets a source is connected to.

  if (raw_graph.edges === undefined) {
    return connections;
  }

  for (const raw_edge of raw_graph.edges) {
    if (connections[raw_edge.source] === undefined) {
      connections[raw_edge.source] = [];
    }
    connections[raw_edge.source].push([
      raw_edge.target,
      raw_edge.label,
      raw_edge.info,
      raw_edge.description,
      raw_edge.description_markup,
    ]);
  }
  return connections;
}

/**
 * Compares the levels of the source and target in the forest
 * that was created by digl.
 * @param {String} source
 * @param {String} target
 * @param {[[[String]]]} forest
 * @returns 1, 2, 3, 4:
 *  1. if the source level comes first
 *  2. if level same but source at top
 *  3. if level same but target at top
 *  4. if source level is afterwards.
 */
function is_source_previous_level(source, target, forest) {
  const source_level = find_level(source, forest);
  const target_level = find_level(target, forest);
  if (source_level < target_level) {
    return 1;
  }
  if (source_level === target_level) {
    const source_posn = find_position_in_level(source, forest);
    const target_posn = find_position_in_level(target, forest);
    return source_posn < target_posn ? 2 : 3;
  }
  return 4;
}

// Get the label of the connection between source and target in connections.
function get_label(connections, source, target) {
  for (const s of Object.keys(connections)) {
    for (const tup of connections[s]) {
      // console.log(tup);
      if (target === tup[0] && s === source) return tup[1];
    }
  }
  return "";
}

// Check if there is a connection between source and target in connections.
function check_connection(connections, source, target) {
  for (const s of Object.keys(connections)) {
    for (const tup of connections[s]) {
      // console.log(tup);
      if (target === tup[0] && s === source) return true;
    }
  }
  return false;
}

function create_handle_if_reqd(
  node,
  handle_destn,
  handle_dirn,
  handles_tracker,
  final_nodes
) {
  const handle_id = create_handle_id(node, handle_destn, handle_dirn);
  if (
    handles_tracker &&
    handles_tracker[node] &&
    handles_tracker[node][handle_destn.short] &&
    !handles_tracker[node][handle_destn.short][handle_dirn.short]
  ) {
    var handle = {
      id: handle_id,
      type: handle_destn.long,
      position: handle_dirn.long,
    };
    final_nodes[node]["data"]["handles"].push(handle);
    handles_tracker[node][handle_destn.short][handle_dirn.short] = true;
  }
  return handle_id;
}

/**
 * Helper function to see if we know the node-type or not.
 */
function is_type_known(raw_graph_node_type) {
  var retValue = Object.keys(allNodeTypes.default).includes(
    raw_graph_node_type
  );
  if (!retValue) {
    // console.log("Unknown node type: " + raw_graph_node_type);
  }
  return retValue;
}

/**
 * Finds the position (2nd dimension) of node in the forest.
 * @param {String} node
 * @param {[[[String]]]} forest
 * @returns 2nd dimension (integer)
 */

function find_level(node, forest) {
  for (const sub_forest of forest) {
    for (const [i, level] of sub_forest.entries()) {
      if (level.includes(node)) {
        return i;
      }
    }
  }
}

/**
 * Finds the position (3rd dimension) of node in the forest.
 * @param {String} node
 * @param {[[[String]]]} forest
 * @returns 2nd dimension (integer)
 */
function find_position_in_level(node, forest) {
  for (const sub_forest of forest) {
    for (const level of sub_forest) {
      if (level.includes(node)) {
        return level.indexOf(node);
      }
    }
  }
}

function create_handle_id(node, handle_destn, handle_dirn) {
  return node + "_" + handle_destn.short + handle_dirn.short;
}

/**
 * Logs information to the console.
 *
 * @param {string} desc
 * @param {JSON} json_obj
 */
// function log_to_console(desc, json_obj) {
//     Object.entries(json_obj).forEach(([key, value]) => {
//       console.log(key, value);
//     });
// }