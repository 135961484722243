import "./SWArchVisualizer.css";
import product_img from "../../../../../images/product_img.svg";
import product_image from "../../../../../images/product_image.png";
import code_view from "../../../../../images/code_view_image.svg";
import jigsawml_logo from "../../../../../images/JigsawML_logo_light.svg";
import product_frame from "../../../../../images/product_frame.svg";
import product_shot from "../../../../../images/Product-shot.svg";
import product_arrow from "../../../../../images/product_arrow.svg";
import Line from "../../../../../images/product_Line.svg";
import prime_benefit from "../../../../../images/product_prime_benefit.svg";
import vector from "../../../../../images/product_vector.svg";
import workforce from "../../../../../images/workforce.svg";
import security from "../../../../../images/product_security.svg";
import documentation from "../../../../../images/Documentation.svg";
import precision from "../../../../../images/precision.svg";
import amount from "../../../../../images/amount.svg";
import collaboration from "../../../../../images/peak_collaboration.svg";
import star from "../../../../../images/Notable_feature.svg";
import AI_assistant from "../../../../../images/AI_assistant.svg";
import component from "../../../../../images/component.svg";
import customer_request from "../../../../../images/customer_request.svg";
import local_installer from "../../../../../images/local_installer.svg";
import custom_notes from "../../../../../images/custom_notes.svg";
import eye from "../../../../../images/eye_svg.svg";
import granular from "../../../../../images/granular_clarity.svg";
import intuitive_visual from "../../../../../images/intuitive_visualisation.svg";
import Granular_clarity from "../../../../../images/GranularClarity.svg";
import Decor from "../../../../../images/Decor.svg";
import edge from "../../../../../images/Edge.svg";
import intelligence_ai from "../../../../../images/intelligence_AI.svg";
import comprehensive_scanner from "../../../../../images/comprehensiveScanner.svg";
import resource_console from "../../../../../images/resource_console.svg";
import light_curve from "../../../../../images/Light curve.svg";
import SWArchVisualizerFooter from "./SWArchVisualizerFooter";
import useFormHandler from "../../../../NewLanding2/useFormHandler";
import AccessModal from "../../../../NewLanding2/AccessModal";
import ThanksModal from "../../../../NewLanding2/ThanksModal";
import Navbar from "../../../../NewLanding2/Navbar";

const Index = () => {

    const {
        email,
        name,
        affiliation,
        message,
        isValidEmail,
        emailError,
        showAccessModal,
        showThanksModal,
        isSubmitting,
        formRef,
        setEmailHandler,
        setNameHandler,
        setAffiliationHandler,
        setMessageHandler,
        formSubmitHandler,
        setEmail,
        setName,
        setAffiliation,
        setMessage,
        setIsValidEmail,
        setEmailError,
        setShowAccessModal,
        setShowThanksModal,
    } = useFormHandler();

    const benefits = [
        {
            icon: workforce,
            title: "Swift Workforce Onboarding",
            description:
                "New team members can explore the codebase in minutes without needing to rely on senior engineers.",
        },
        {
            icon: documentation,
            title: "Stay Updated with Auto-Sync",
            description:
                "Option to enable auto-sync, ensuring you always have the latest software architecture diagrams and documents.",
        },
        {
            icon: precision,
            title: "Fast and Precise Responses",
            description:
                "Assist DevOps engineers with precise answers about the codebase when experts and developers are unavailable.",
        },
        {
            icon: amount,
            title: "Optimize Cloud Spending",
            description:
                "Understand cost breakdowns and detect anomalies—no engineering intervention needed.",
        },
        {
            icon: security,
            title: "Security & Compliance",
            description:
                "Enable auditors, incident responders, and MSPs with actionable insights, enhancing efficiency.",
        },
        {
            icon: collaboration,
            title: "Seamless Team Collaboration",
            description:
                "Team members can leave notes for others to review, fostering seamless communication and improving workflow efficiency.",
        },
    ];

    const features = [
        {
            icon: AI_assistant,
            description: 'AI assistant for your software queries',
        },
        {
            icon: component,
            description: 'Identify every component & where it’s implemented',
        },
        {
            icon: local_installer,
            description: 'Available as a local installer to run on your premises',
        },
        {
            icon: customer_request,
            description: 'Find customer request flows automatically',
        }
    ]

    return (
        <div className="product-container">
            <div className="product-bg">
                <div className="product-header">
                    <Navbar/>
                </div>
                <div className="product-image-container">
                    <img src={product_img} alt="product_img" className="product-new-image" />
                    <img src={product_image} alt="product_image" className="product-image-mobile" />
                </div>
                <img src={code_view} alt="code_view" className="code-view-image" />
                <div className="product-text-container">
                    <span className="product-title">
                        Give life to your static system design blueprints with our
                    </span>
                    <strong className="product-subtitle-main">
                        AI Powered
                    </strong>
                    <strong className="product-subtitle-highlight">
                        Software Architecture Visualizer
                    </strong>
                    <span className="product-description">
                        Get the most interactive and accurate visualization of your software to onboard new team members, understand cloud costs and troubleshoot issues.
                    </span>
                    <button className="btn-for-signup-beta" type="button"
                        onClick={() => {
                            setEmail("");
                            setName("");
                            setAffiliation("");
                            setMessage("");
                            setIsValidEmail(null);
                            setEmailError(false);
                            setShowAccessModal(true);
                        }}
                    >
                        Sign up for Beta
                    </button>
                </div>
                {/* architecture-container */}
                <div className="architecture-container">
                    <strong className="architecture-title">Map Your Architecture To Code</strong>
                    <div className="architecture-description">
                        Our proprietary engine uses AI Agents and dynamic Knowledge Graphs to transform your code into an information-rich visualization.
                    </div>
                </div>
                {/* prime-benefits-container*/}
                <div className="prime-benefits-container">
                    {/* product section */}
                    <div className="product-section">
                        <div className="product-info">
                            <span className="product-text">Everything cloud teams need</span>
                            <span><img src={product_arrow} alt="product_arrow" /></span>
                        </div>
                        <div className="product-images">
                            <img src={product_shot} alt="product_shot" />
                            <img src={product_frame} alt="product_frame" className="product-frame" />
                        </div>
                    </div>
                    {/* prime benefits */}
                    <div className="prime-benefits-header">
                        <img src={Line} alt="Line" className="line" />
                        <img src={prime_benefit} alt="prime_benefit" className="prime-benefit-icon" />
                        <strong className="prime-benefits-title">Prime Benefits</strong>
                        <img src={prime_benefit} alt="prime_benefit" className="prime-benefit-icon" />
                        <img src={Line} alt="Line" className="line_rotated" />
                    </div>
                    <div className="prime-benefits-vector">
                        <img src={vector} alt="vector" />
                    </div>

                    <div className="benefits-container">
                        {benefits.map((item, index) => {
                            return (
                                <div className="benefit-item">
                                    <span key={item.id}><img src={item.icon} alt={item.icon} /></span>
                                    <span className="benefit-title">{item.title}</span>
                                    <span className="benefit-description">{item.description}</span>
                                </div>
                            )
                        })}
                    </div>

                    {/* Notable Features */}

                    <div className="features-section">

                        {/* Features Grid */}
                        <div className="features-grid">
                            <div className="title-wrapper">
                                <img src={star} alt="star" />
                                <strong className="notable-heading">Notable Features</strong>
                            </div>

                            <div className="features-container">
                                {features.map((item, index) => (
                                    <div className="feature-card"
                                        style={{ width: index === 0 || index === 3 ? '36%' : '40%' }}
                                    >
                                        <span className="icon-container">
                                            <img src={item.icon} alt={item.icon} />
                                        </span>
                                        <span className="description">
                                            {item.description}
                                        </span>
                                    </div>
                                ))}
                            </div>
                            <div className="container-wrapper">
                                <div className="divider-left" />
                                <div className="feature-card-container">
                                    <div className="feature-card">
                                        <span className="icon-container">
                                            <img src={custom_notes} alt="custom_notes" />
                                        </span>
                                        <span className="description">
                                            Leave custom notes for your team members
                                        </span>
                                    </div>
                                </div>
                                <div className="divider-right" />
                            </div>

                        </div>
                        <div className="features-section-bg">
                        </div>
                    </div>
                    <div className="prime-benefits-container-bg">
                    </div>
                </div>
                {/* Bridge Design Infrastructure  */}

                <div className="bridge-container">
                    <div className="bridge-header-section">
                        <strong className="bridge-main-title">Bridge Design Infrastructure</strong>
                        <div className="bridge-sub-title">
                            A real-time cloud architecture map scan to identify every resource inside your organization
                        </div>
                    </div>
                    <div className="bridge-cards-section">
                        <div className="bridge-cards-container">
                            {/* Left Card */}
                            <div className="bridge-left-card">
                                <div className="bridge-card-content">
                                    <div className="bridge-card-header">
                                        <span><img src={eye} alt="eye" /></span>
                                        <span className="bridge-card-header-text">Intuitive Visualisation</span>
                                    </div>
                                    <div className="bridge-card-title">Dynamic, Detailed, and Accurate</div>
                                    <div className="bridge-card-description">
                                        Without bothering senior engineers anymore, New team members dive deeper into your systems to the level of depth they are comfortable with.
                                    </div>
                                </div>
                                <div className="bridge-card-image"><img src={intuitive_visual} alt="intuitive_visual" /></div>
                            </div>

                            {/* Right Card */}
                            <div className="bridge-right-card">
                                <div className="bridge-card-content">
                                    <div className="bridge-card-header">
                                        <span><img src={granular} alt="granular" /></span>
                                        <span className="bridge-card-header-text">Granular Clarity</span>
                                    </div>
                                    <div className="bridge-card-title">Search, Locate & Map Out Hidden Assets</div>
                                    <div className="bridge-card-description">
                                        Without bothering senior engineers anymore, New team members dive deeper into your systems to the level of depth they are comfortable with.
                                    </div>
                                </div>
                                <div className="bridge-card-image"><img src={Granular_clarity} alt="Granular_clarity" /></div>
                            </div>
                        </div>
                    </div>
                    <div className="decor-container">
                        <img src={Decor} alt="Decor" />
                    </div>
                </div>


                {/* Edge container */}

                <div className="edge-section-container">
                    <div className="header-container">
                        <strong className="header-text">
                            Get An Extra
                            <span className="header-edge">Edge</span> With
                        </strong>
                        <img
                            src={edge}
                            alt="edge"
                            className="edge-img"
                        />
                    </div>

                    <div className="image-container">
                        <img src={comprehensive_scanner} alt="comprehensive_scanner" />
                        <img src={intelligence_ai} alt="intelligence_ai" />
                        <img src={resource_console} alt="resource_console" />
                        <div className="image-container-bg">
                        </div>
                    </div>

                    <div className="subscribe-container">
                        <span className="subscribe-text">Subscribe to our newsletter</span>
                        <div className="email-container">
                            <input
                                type="email"
                                placeholder="Type your email"
                                className="email-input"
                            />
                            <button className="subscribe-button">
                                Subscribe
                            </button>
                        </div>
                        <div className="subscribe-container-bg">
                        </div>
                    </div>
                </div>

                {/* Footer Section */}
                <div>
                    <img src={light_curve} alt="light_curve" style={{ width: '100%' }} />
                    <SWArchVisualizerFooter />
                </div>
            </div>
            <AccessModal
                show={showAccessModal}
                handleClose={() => setShowAccessModal(false)}
                formRef={formRef}
                name={name}
                affiliation={affiliation}
                message={message}
                email={email}
                isValidEmail={isValidEmail}
                emailError={emailError}
                isSubmitting={isSubmitting}
                setNameHandler={setNameHandler}
                setAffiliationHandler={setAffiliationHandler}
                setMessageHandler={setMessageHandler}
                setEmailHandler={setEmailHandler}
                formSubmitHandler={formSubmitHandler}
            />
            <ThanksModal
                show={showThanksModal}
                handleClose={() => setShowThanksModal(false)}
            />
        </div>
    );
};

export default Index;
