import React from 'react'
import Modal from 'react-bootstrap/Modal';
import './reachOutToUs.css';
import linkedInLogo from '../../../../images/linkedInLogo.svg'
import sendMessageLogo from '../../../../images/sendMessageLogo.svg'

function ReachOutToUs(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName='demoModalContainer'
    >
      <Modal.Body className='demoModalBody'>
        <div className='demoModalBodyfirstsection'>
          <div className='reachLabel'>Reach Out To Us</div>
          <div><div className='linkedInLogoContainer'><img src={linkedInLogo} alt='linkedInLogo' className='linkedInLogo' /></div></div>
        </div>
        <div>
          <form className='demoModalForm'>
            <div className='formLabel'>Share Your Contact Details</div>
            <div><input className='demoModalInput' placeholder='Your name' /></div>
            <div><input className='demoModalInput' placeholder='Your work email' /></div>
            <div><input className='demoModalInput' placeholder='Your company name' /></div>
            <div><input className='demoModalInput' placeholder='Your role' /></div>
            <div><textarea className='demoModalTextArea ' placeholder='Write your message (optional)' /></div>
            <div style={{ position: 'relative' }}>
              <button className='sendMsgButton' disabled>Send Message</button>
              <img src={sendMessageLogo} alt='sendMessageLogo' className='sendMessageLogo' />
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ReachOutToUs
